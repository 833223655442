import Vue from 'vue'
import vueDebounce from 'vue-debounce'
import VueClipboard from 'vue-clipboard2'
import VueScrollTo from 'vue-scrollto'

Vue.use(vueDebounce, {
  defaultTime: '500ms',
  listenTo: ['input', 'change']
})

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 100,
  easing: 'ease',
  force: true
})
