
import { ValidationProvider } from 'vee-validate'
import { EDateType } from '@/helpers/enums'

export default {
  name: 'FormFieldPickerDate',

  components: { ValidationProvider },

  inheritAttrs: false,

  props: {
    customMessages: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    pickerType: {
      type: String,
      default: 'date'
    },
    startFrom: {
      type: String,
      default: 'YEAR'
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    format: {
      type: String,
      default: EDateType.LONG
    }
  },

  data() {
    return {
      activePicker: null,
      dialog: false,
      displayDate: '',
      selectDate: ''
    }
  },

  computed: {
    internalValueFormat() {
      return this.format === EDateType.LONG ? 'YYYY-MM-DD' : 'YYYY-MM'
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.displayDate = ''

        if (value) {
          const { format } = this
          this.displayDate = this.$displayDate({ value, format })
          this.selectDate = this.$showDate(value, this.internalValueFormat)
        }
      }
    },

    dialog(visible) {
      if (visible) {
        if (this.value) {
          this.selectDate = this.$showDate(this.value, this.internalValueFormat)
        } else {
          this.selectDate = this.$showDate(new Date(), this.internalValueFormat)
        }

        this.$nextTick(() => (this.activePicker = this.startFrom))
      }
    },

    selectDate(value) {
      const { format } = this
      this.displayDate = this.$showDate({ value, format })
      this.$emit('input', this.$sendDate(value))
      this.$emit('change', this.$sendDate(value))
    }
  }
}
