import { EGtmEvent } from '@/helpers/enums'
import { extractMspGtmData } from '@/helpers/tracking'

export default function ({ app, store }) {
  const auth = app.$auth

  if (auth.loggedIn) {
    app.$pushEvent(EGtmEvent.MSP_DATA, extractMspGtmData({ auth, store }), true)
  }
}
