
export default {
  name: 'LayoutAnnouncementTemplate',

  data() {
    return {
      announce: false,
      deadline: '',
      message: '',
      canSnooze: true
    }
  },

  async created() {
    try {
      const { data } = await this.$api.utils.getAnnouncement()

      if (data.length) {
        this.deadline = this.$moment(data[0].deadline)
        this.triggerAnnouncement(this.deadline)
      } else {
        this.announce = false
      }
    } catch (error) {
      console.error(error)
    }
  },

  methods: {
    snooze() {
      const now = this.$moment(new Date())
      const diff = this.deadline.diff(now, 'seconds')

      if (diff < 600) {
        if (!this.$auth.$storage.getState('tenSnooze')) {
          this.$auth.$storage.setState('tenSnooze', true)
        }
      } else if (diff < 1800) {
        if (!this.$auth.$storage.getState('thirtySnooze')) {
          this.$auth.$storage.setState('thirtySnooze', true)
        }
      } else if (diff >= 1800) {
        if (!this.$auth.$storage.getState('longSnooze')) {
          this.$auth.$storage.setState('longSnooze', true)
        }
      }

      this.announce = false
    },

    triggerAnnouncement(time) {
      const now = this.$moment(new Date())
      const diff = time.diff(now, 'seconds')

      if (diff < 90) {
        this.announce = true
        this.canSnooze = false
        this.message = this.$t('msp.v2.announcment.banner.one', {
          time: time.format('HH:mm')
        })
      } else if (diff < 600) {
        if (!this.$auth.$storage.getState('tenSnooze')) {
          this.announce = true
          this.message = this.$t('msp.v2.announcment.banner.ten', {
            time: time.format('HH:mm')
          })
        }
      } else if (diff < 1800) {
        if (!this.$auth.$storage.getState('thirtySnooze')) {
          this.announce = true
          this.message = this.$t('msp.v2.announcment.banner.thirty', {
            time: time.format('HH:mm')
          })
        }
      } else if (diff >= 1800) {
        if (!this.$auth.$storage.getState('longSnooze')) {
          this.announce = true
          this.message = this.$t('msp.v2.announcment.banner.long', {
            time: time.format('HH:mm')
          })
        }
      }
    }
  }
}
