
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      pageNotFound: this.$t('msp.v2.message.generic.error.page.title'),
      otherError: this.$t('msp.v2.message.generic.error.page.other')
    }
  },

  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  }
}
