
import { mapGetters } from 'vuex'
import { EManualLogType } from '@/helpers/enums'

export default {
  name: 'AppTabNotes',

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapGetters({
      notes: 'application/notes'
    }),

    applicationId() {
      return this.$route.params.appId
    },

    types() {
      return [
        {
          id: 'note',
          value: EManualLogType.NOTE,
          text: this.$t('msp.v2.notes.types.note')
        },
        {
          id: 'interview',
          value: EManualLogType.INTERVIEW,
          text: this.$t('msp.v2.notes.types.interview')
        },
        {
          id: 'call',
          value: EManualLogType.CALL,
          text: this.$t('msp.v2.notes.types.call')
        }
      ]
    }
  },

  async beforeMount() {
    if (!this.notes?.length) {
      try {
        await this.$store.dispatch('application/fetchHistory', this.applicationId)
      } finally {
        this.loading = false
      }
    } else {
      this.loading = false
    }
  },

  methods: {
    async createNote(note) {
      await this.$api.application.createManualLog(this.applicationId, note)
      await this.$store.dispatch('application/fetchHistory', this.applicationId)
    },

    async deleteNote(noteId) {
      await this.$api.application.deleteManualLog(this.applicationId, noteId)
      await this.$store.dispatch('application/deleteNote', noteId)
    },

    editionTransformer(note) {
      return { ...note.newValue }
    },

    previewTransformer(note) {
      return {
        content: note.newValue.note,
        createdAt: note.created,
        createdBy: `${note.msp.firstName} ${note.msp.lastName}`,
        executionDate: note.newValue.executionDate,
        type: note.newValue.actionType
      }
    },

    async updateNote(noteId, note) {
      await this.$api.application.updateManualLog(this.applicationId, noteId, note)
      await this.$store.dispatch('application/fetchHistory', this.applicationId)
    }
  }
}
