export const AppAlertClientRequest = () => import('../../components/App/AlertClientRequest.vue' /* webpackChunkName: "components/app-alert-client-request" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsAssessments = () => import('../../components/App/DetailsAssessments.vue' /* webpackChunkName: "components/app-details-assessments" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsClientRequests = () => import('../../components/App/DetailsClientRequests.vue' /* webpackChunkName: "components/app-details-client-requests" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsDrawer = () => import('../../components/App/DetailsDrawer.vue' /* webpackChunkName: "components/app-details-drawer" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsExpander = () => import('../../components/App/DetailsExpander.vue' /* webpackChunkName: "components/app-details-expander" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsProject = () => import('../../components/App/DetailsProject.vue' /* webpackChunkName: "components/app-details-project" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsProjectHeader = () => import('../../components/App/DetailsProjectHeader.vue' /* webpackChunkName: "components/app-details-project-header" */).then(c => wrapFunctional(c.default || c))
export const AppDetailsSalary = () => import('../../components/App/DetailsSalary.vue' /* webpackChunkName: "components/app-details-salary" */).then(c => wrapFunctional(c.default || c))
export const AppFlowInterviewDetails = () => import('../../components/App/FlowInterviewDetails.vue' /* webpackChunkName: "components/app-flow-interview-details" */).then(c => wrapFunctional(c.default || c))
export const AppFlowOfferDetails = () => import('../../components/App/FlowOfferDetails.vue' /* webpackChunkName: "components/app-flow-offer-details" */).then(c => wrapFunctional(c.default || c))
export const AppHistoryEmail = () => import('../../components/App/HistoryEmail.vue' /* webpackChunkName: "components/app-history-email" */).then(c => wrapFunctional(c.default || c))
export const AppHistoryMspAnswerEdited = () => import('../../components/App/HistoryMspAnswerEdited.vue' /* webpackChunkName: "components/app-history-msp-answer-edited" */).then(c => wrapFunctional(c.default || c))
export const AppHistoryUpdated = () => import('../../components/App/HistoryUpdated.vue' /* webpackChunkName: "components/app-history-updated" */).then(c => wrapFunctional(c.default || c))
export const AppPanelDetails = () => import('../../components/App/PanelDetails.vue' /* webpackChunkName: "components/app-panel-details" */).then(c => wrapFunctional(c.default || c))
export const AppPanelHeader = () => import('../../components/App/PanelHeader.vue' /* webpackChunkName: "components/app-panel-header" */).then(c => wrapFunctional(c.default || c))
export const AppPanelHistory = () => import('../../components/App/PanelHistory.vue' /* webpackChunkName: "components/app-panel-history" */).then(c => wrapFunctional(c.default || c))
export const AppPanelNavigation = () => import('../../components/App/PanelNavigation.vue' /* webpackChunkName: "components/app-panel-navigation" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionCard = () => import('../../components/App/QuestionCard.vue' /* webpackChunkName: "components/app-question-card" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionCheckbox = () => import('../../components/App/QuestionCheckbox.vue' /* webpackChunkName: "components/app-question-checkbox" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionDate = () => import('../../components/App/QuestionDate.vue' /* webpackChunkName: "components/app-question-date" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionDropdown = () => import('../../components/App/QuestionDropdown.vue' /* webpackChunkName: "components/app-question-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionElement = () => import('../../components/App/QuestionElement.vue' /* webpackChunkName: "components/app-question-element" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionNumber = () => import('../../components/App/QuestionNumber.vue' /* webpackChunkName: "components/app-question-number" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionRadio = () => import('../../components/App/QuestionRadio.vue' /* webpackChunkName: "components/app-question-radio" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionText = () => import('../../components/App/QuestionText.vue' /* webpackChunkName: "components/app-question-text" */).then(c => wrapFunctional(c.default || c))
export const AppQuestionTextarea = () => import('../../components/App/QuestionTextarea.vue' /* webpackChunkName: "components/app-question-textarea" */).then(c => wrapFunctional(c.default || c))
export const AppTabAttachments = () => import('../../components/App/TabAttachments.vue' /* webpackChunkName: "components/app-tab-attachments" */).then(c => wrapFunctional(c.default || c))
export const AppTabNotes = () => import('../../components/App/TabNotes.vue' /* webpackChunkName: "components/app-tab-notes" */).then(c => wrapFunctional(c.default || c))
export const AppTabResume = () => import('../../components/App/TabResume.vue' /* webpackChunkName: "components/app-tab-resume" */).then(c => wrapFunctional(c.default || c))
export const AppTabScreening = () => import('../../components/App/TabScreening.vue' /* webpackChunkName: "components/app-tab-screening" */).then(c => wrapFunctional(c.default || c))
export const BaseAssigneesPanel = () => import('../../components/Base/AssigneesPanel.vue' /* webpackChunkName: "components/base-assignees-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseAvatarTemplate = () => import('../../components/Base/AvatarTemplate.vue' /* webpackChunkName: "components/base-avatar-template" */).then(c => wrapFunctional(c.default || c))
export const BaseCardPanel = () => import('../../components/Base/CardPanel.vue' /* webpackChunkName: "components/base-card-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseChipType = () => import('../../components/Base/ChipType.vue' /* webpackChunkName: "components/base-chip-type" */).then(c => wrapFunctional(c.default || c))
export const BaseCountDown = () => import('../../components/Base/CountDown.vue' /* webpackChunkName: "components/base-count-down" */).then(c => wrapFunctional(c.default || c))
export const BaseDetailsPanel = () => import('../../components/Base/DetailsPanel.vue' /* webpackChunkName: "components/base-details-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseDottedMenu = () => import('../../components/Base/DottedMenu.vue' /* webpackChunkName: "components/base-dotted-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseDottedMenuEntry = () => import('../../components/Base/DottedMenuEntry.vue' /* webpackChunkName: "components/base-dotted-menu-entry" */).then(c => wrapFunctional(c.default || c))
export const BaseEmailTemplates = () => import('../../components/Base/EmailTemplates.vue' /* webpackChunkName: "components/base-email-templates" */).then(c => wrapFunctional(c.default || c))
export const BaseExpansionPanel = () => import('../../components/Base/ExpansionPanel.vue' /* webpackChunkName: "components/base-expansion-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseHistoryItem = () => import('../../components/Base/HistoryItem.vue' /* webpackChunkName: "components/base-history-item" */).then(c => wrapFunctional(c.default || c))
export const BaseImagePlaceholder = () => import('../../components/Base/ImagePlaceholder.vue' /* webpackChunkName: "components/base-image-placeholder" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileLeftDrawer = () => import('../../components/Base/MobileLeftDrawer.vue' /* webpackChunkName: "components/base-mobile-left-drawer" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileMenu = () => import('../../components/Base/MobileMenu.vue' /* webpackChunkName: "components/base-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseMobileRightDrawer = () => import('../../components/Base/MobileRightDrawer.vue' /* webpackChunkName: "components/base-mobile-right-drawer" */).then(c => wrapFunctional(c.default || c))
export const BaseModalTemplate = () => import('../../components/Base/ModalTemplate.vue' /* webpackChunkName: "components/base-modal-template" */).then(c => wrapFunctional(c.default || c))
export const BaseNoResults = () => import('../../components/Base/NoResults.vue' /* webpackChunkName: "components/base-no-results" */).then(c => wrapFunctional(c.default || c))
export const BaseNoteEdit = () => import('../../components/Base/NoteEdit.vue' /* webpackChunkName: "components/base-note-edit" */).then(c => wrapFunctional(c.default || c))
export const BaseNotePreview = () => import('../../components/Base/NotePreview.vue' /* webpackChunkName: "components/base-note-preview" */).then(c => wrapFunctional(c.default || c))
export const BaseNotesPanel = () => import('../../components/Base/NotesPanel.vue' /* webpackChunkName: "components/base-notes-panel" */).then(c => wrapFunctional(c.default || c))
export const BasePlaceholderButton = () => import('../../components/Base/PlaceholderButton.vue' /* webpackChunkName: "components/base-placeholder-button" */).then(c => wrapFunctional(c.default || c))
export const BasePollingTemplate = () => import('../../components/Base/PollingTemplate.vue' /* webpackChunkName: "components/base-polling-template" */).then(c => wrapFunctional(c.default || c))
export const BaseQuestionsPanel = () => import('../../components/Base/QuestionsPanel.vue' /* webpackChunkName: "components/base-questions-panel" */).then(c => wrapFunctional(c.default || c))
export const BaseRightInfoTabs = () => import('../../components/Base/RightInfoTabs.vue' /* webpackChunkName: "components/base-right-info-tabs" */).then(c => wrapFunctional(c.default || c))
export const BaseSearchField = () => import('../../components/Base/SearchField.vue' /* webpackChunkName: "components/base-search-field" */).then(c => wrapFunctional(c.default || c))
export const BaseShowMore = () => import('../../components/Base/ShowMore.vue' /* webpackChunkName: "components/base-show-more" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebarControls = () => import('../../components/Base/SidebarControls.vue' /* webpackChunkName: "components/base-sidebar-controls" */).then(c => wrapFunctional(c.default || c))
export const BaseStatusChip = () => import('../../components/Base/StatusChip.vue' /* webpackChunkName: "components/base-status-chip" */).then(c => wrapFunctional(c.default || c))
export const BaseUserMenu = () => import('../../components/Base/UserMenu.vue' /* webpackChunkName: "components/base-user-menu" */).then(c => wrapFunctional(c.default || c))
export const DashboardFilterAssignees = () => import('../../components/Dashboard/FilterAssignees.vue' /* webpackChunkName: "components/dashboard-filter-assignees" */).then(c => wrapFunctional(c.default || c))
export const DashboardFilterStatus = () => import('../../components/Dashboard/FilterStatus.vue' /* webpackChunkName: "components/dashboard-filter-status" */).then(c => wrapFunctional(c.default || c))
export const DashboardOrderButton = () => import('../../components/Dashboard/OrderButton.vue' /* webpackChunkName: "components/dashboard-order-button" */).then(c => wrapFunctional(c.default || c))
export const FormFieldAutocomplete = () => import('../../components/Form/FieldAutocomplete.vue' /* webpackChunkName: "components/form-field-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormFieldChangePassword = () => import('../../components/Form/FieldChangePassword.vue' /* webpackChunkName: "components/form-field-change-password" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCheckbox = () => import('../../components/Form/FieldCheckbox.vue' /* webpackChunkName: "components/form-field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormFieldCountry = () => import('../../components/Form/FieldCountry.vue' /* webpackChunkName: "components/form-field-country" */).then(c => wrapFunctional(c.default || c))
export const FormFieldInput = () => import('../../components/Form/FieldInput.vue' /* webpackChunkName: "components/form-field-input" */).then(c => wrapFunctional(c.default || c))
export const FormFieldInput2 = () => import('../../components/Form/FieldInput2.vue' /* webpackChunkName: "components/form-field-input2" */).then(c => wrapFunctional(c.default || c))
export const FormFieldLegalEntities = () => import('../../components/Form/FieldLegalEntities.vue' /* webpackChunkName: "components/form-field-legal-entities" */).then(c => wrapFunctional(c.default || c))
export const FormFieldPickerChip = () => import('../../components/Form/FieldPickerChip.vue' /* webpackChunkName: "components/form-field-picker-chip" */).then(c => wrapFunctional(c.default || c))
export const FormFieldPickerDate = () => import('../../components/Form/FieldPickerDate.vue' /* webpackChunkName: "components/form-field-picker-date" */).then(c => wrapFunctional(c.default || c))
export const FormFieldPickerDate2 = () => import('../../components/Form/FieldPickerDate2.vue' /* webpackChunkName: "components/form-field-picker-date2" */).then(c => wrapFunctional(c.default || c))
export const FormFieldPickerDateTime = () => import('../../components/Form/FieldPickerDateTime.vue' /* webpackChunkName: "components/form-field-picker-date-time" */).then(c => wrapFunctional(c.default || c))
export const FormFieldRadio = () => import('../../components/Form/FieldRadio.vue' /* webpackChunkName: "components/form-field-radio" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelect = () => import('../../components/Form/FieldSelect.vue' /* webpackChunkName: "components/form-field-select" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelectCurrency = () => import('../../components/Form/FieldSelectCurrency.vue' /* webpackChunkName: "components/form-field-select-currency" */).then(c => wrapFunctional(c.default || c))
export const FormFieldSelectLanguage = () => import('../../components/Form/FieldSelectLanguage.vue' /* webpackChunkName: "components/form-field-select-language" */).then(c => wrapFunctional(c.default || c))
export const FormFieldTextarea = () => import('../../components/Form/FieldTextarea.vue' /* webpackChunkName: "components/form-field-textarea" */).then(c => wrapFunctional(c.default || c))
export const HistoryAuthorTooltip = () => import('../../components/History/AuthorTooltip.vue' /* webpackChunkName: "components/history-author-tooltip" */).then(c => wrapFunctional(c.default || c))
export const HistoryEntriesPanel = () => import('../../components/History/EntriesPanel.vue' /* webpackChunkName: "components/history-entries-panel" */).then(c => wrapFunctional(c.default || c))
export const HistoryEntryTemplate = () => import('../../components/History/EntryTemplate.vue' /* webpackChunkName: "components/history-entry-template" */).then(c => wrapFunctional(c.default || c))
export const HistoryEntryToggler = () => import('../../components/History/EntryToggler.vue' /* webpackChunkName: "components/history-entry-toggler" */).then(c => wrapFunctional(c.default || c))
export const HistoryEntryWithFromTo = () => import('../../components/History/EntryWithFromTo.vue' /* webpackChunkName: "components/history-entry-with-from-to" */).then(c => wrapFunctional(c.default || c))
export const HistoryEntryWithLink = () => import('../../components/History/EntryWithLink.vue' /* webpackChunkName: "components/history-entry-with-link" */).then(c => wrapFunctional(c.default || c))
export const HistoryEntryWithModal = () => import('../../components/History/EntryWithModal.vue' /* webpackChunkName: "components/history-entry-with-modal" */).then(c => wrapFunctional(c.default || c))
export const HistoryLabelWithAuthor = () => import('../../components/History/LabelWithAuthor.vue' /* webpackChunkName: "components/history-label-with-author" */).then(c => wrapFunctional(c.default || c))
export const LayoutAnnouncementTemplate = () => import('../../components/Layout/AnnouncementTemplate.vue' /* webpackChunkName: "components/layout-announcement-template" */).then(c => wrapFunctional(c.default || c))
export const LayoutApplicationTemplate = () => import('../../components/Layout/ApplicationTemplate.vue' /* webpackChunkName: "components/layout-application-template" */).then(c => wrapFunctional(c.default || c))
export const LayoutInnerTemplate = () => import('../../components/Layout/InnerTemplate.vue' /* webpackChunkName: "components/layout-inner-template" */).then(c => wrapFunctional(c.default || c))
export const LayoutMainTemplate = () => import('../../components/Layout/MainTemplate.vue' /* webpackChunkName: "components/layout-main-template" */).then(c => wrapFunctional(c.default || c))
export const LayoutSystemTemplate = () => import('../../components/Layout/SystemTemplate.vue' /* webpackChunkName: "components/layout-system-template" */).then(c => wrapFunctional(c.default || c))
export const ModalBulkRejectConfirmation = () => import('../../components/Modal/BulkRejectConfirmation.vue' /* webpackChunkName: "components/modal-bulk-reject-confirmation" */).then(c => wrapFunctional(c.default || c))
export const ModalFlowClientRequest = () => import('../../components/Modal/FlowClientRequest.vue' /* webpackChunkName: "components/modal-flow-client-request" */).then(c => wrapFunctional(c.default || c))
export const ModalFlowPendingPoolApps = () => import('../../components/Modal/FlowPendingPoolApps.vue' /* webpackChunkName: "components/modal-flow-pending-pool-apps" */).then(c => wrapFunctional(c.default || c))
export const ModalFlowProject = () => import('../../components/Modal/FlowProject.vue' /* webpackChunkName: "components/modal-flow-project" */).then(c => wrapFunctional(c.default || c))
export const ModalFlowReport = () => import('../../components/Modal/FlowReport.vue' /* webpackChunkName: "components/modal-flow-report" */).then(c => wrapFunctional(c.default || c))
export const ModalFlowTalent = () => import('../../components/Modal/FlowTalent.vue' /* webpackChunkName: "components/modal-flow-talent" */).then(c => wrapFunctional(c.default || c))
export const ModalQuestionEditor = () => import('../../components/Modal/QuestionEditor.vue' /* webpackChunkName: "components/modal-question-editor" */).then(c => wrapFunctional(c.default || c))
export const ModalShareProject = () => import('../../components/Modal/ShareProject.vue' /* webpackChunkName: "components/modal-share-project" */).then(c => wrapFunctional(c.default || c))
export const PoolsHashMenu = () => import('../../components/Pools/HashMenu.vue' /* webpackChunkName: "components/pools-hash-menu" */).then(c => wrapFunctional(c.default || c))
export const PoolsInfoPanel = () => import('../../components/Pools/InfoPanel.vue' /* webpackChunkName: "components/pools-info-panel" */).then(c => wrapFunctional(c.default || c))
export const PoolsMiniCard = () => import('../../components/Pools/MiniCard.vue' /* webpackChunkName: "components/pools-mini-card" */).then(c => wrapFunctional(c.default || c))
export const PoolsPreviewPanel = () => import('../../components/Pools/PreviewPanel.vue' /* webpackChunkName: "components/pools-preview-panel" */).then(c => wrapFunctional(c.default || c))
export const PoolsTypeIndicator = () => import('../../components/Pools/TypeIndicator.vue' /* webpackChunkName: "components/pools-type-indicator" */).then(c => wrapFunctional(c.default || c))
export const ProjectCardActionsSearch = () => import('../../components/Project/CardActionsSearch.vue' /* webpackChunkName: "components/project-card-actions-search" */).then(c => wrapFunctional(c.default || c))
export const ProjectCardPanel = () => import('../../components/Project/CardPanel.vue' /* webpackChunkName: "components/project-card-panel" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsAttachments = () => import('../../components/Project/DetailsAttachments.vue' /* webpackChunkName: "components/project-details-attachments" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsComments = () => import('../../components/Project/DetailsComments.vue' /* webpackChunkName: "components/project-details-comments" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsInfo = () => import('../../components/Project/DetailsInfo.vue' /* webpackChunkName: "components/project-details-info" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsManagers = () => import('../../components/Project/DetailsManagers.vue' /* webpackChunkName: "components/project-details-managers" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsPools = () => import('../../components/Project/DetailsPools.vue' /* webpackChunkName: "components/project-details-pools" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsSkills = () => import('../../components/Project/DetailsSkills.vue' /* webpackChunkName: "components/project-details-skills" */).then(c => wrapFunctional(c.default || c))
export const ProjectDetailsTeam = () => import('../../components/Project/DetailsTeam.vue' /* webpackChunkName: "components/project-details-team" */).then(c => wrapFunctional(c.default || c))
export const ProjectDummyDisabled = () => import('../../components/Project/DummyDisabled.vue' /* webpackChunkName: "components/project-dummy-disabled" */).then(c => wrapFunctional(c.default || c))
export const ProjectHashMenu = () => import('../../components/Project/HashMenu.vue' /* webpackChunkName: "components/project-hash-menu" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryAdminJobChanged = () => import('../../components/Project/HistoryAdminJobChanged.vue' /* webpackChunkName: "components/project-history-admin-job-changed" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryAssigneeUpdated = () => import('../../components/Project/HistoryAssigneeUpdated.vue' /* webpackChunkName: "components/project-history-assignee-updated" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryAttachments = () => import('../../components/Project/HistoryAttachments.vue' /* webpackChunkName: "components/project-history-attachments" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryPoolAdded = () => import('../../components/Project/HistoryPoolAdded.vue' /* webpackChunkName: "components/project-history-pool-added" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryPoolChanged = () => import('../../components/Project/HistoryPoolChanged.vue' /* webpackChunkName: "components/project-history-pool-changed" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryPoolRemoved = () => import('../../components/Project/HistoryPoolRemoved.vue' /* webpackChunkName: "components/project-history-pool-removed" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryReasonForCloseSet = () => import('../../components/Project/HistoryReasonForCloseSet.vue' /* webpackChunkName: "components/project-history-reason-for-close-set" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryStatusChanged = () => import('../../components/Project/HistoryStatusChanged.vue' /* webpackChunkName: "components/project-history-status-changed" */).then(c => wrapFunctional(c.default || c))
export const ProjectHistoryTitleChanged = () => import('../../components/Project/HistoryTitleChanged.vue' /* webpackChunkName: "components/project-history-title-changed" */).then(c => wrapFunctional(c.default || c))
export const ProjectLocationTemplateSelector = () => import('../../components/Project/LocationTemplateSelector.vue' /* webpackChunkName: "components/project-location-template-selector" */).then(c => wrapFunctional(c.default || c))
export const ProjectPanelCreate = () => import('../../components/Project/PanelCreate.vue' /* webpackChunkName: "components/project-panel-create" */).then(c => wrapFunctional(c.default || c))
export const ProjectPanelDetails = () => import('../../components/Project/PanelDetails.vue' /* webpackChunkName: "components/project-panel-details" */).then(c => wrapFunctional(c.default || c))
export const ProjectPanelHeader = () => import('../../components/Project/PanelHeader.vue' /* webpackChunkName: "components/project-panel-header" */).then(c => wrapFunctional(c.default || c))
export const ProjectPanelHistory = () => import('../../components/Project/PanelHistory.vue' /* webpackChunkName: "components/project-panel-history" */).then(c => wrapFunctional(c.default || c))
export const ProjectPanelTeam = () => import('../../components/Project/PanelTeam.vue' /* webpackChunkName: "components/project-panel-team" */).then(c => wrapFunctional(c.default || c))
export const ProjectPipelineActions = () => import('../../components/Project/PipelineActions.vue' /* webpackChunkName: "components/project-pipeline-actions" */).then(c => wrapFunctional(c.default || c))
export const ProjectPoolSelector = () => import('../../components/Project/PoolSelector.vue' /* webpackChunkName: "components/project-pool-selector" */).then(c => wrapFunctional(c.default || c))
export const ProjectStatusControl = () => import('../../components/Project/StatusControl.vue' /* webpackChunkName: "components/project-status-control" */).then(c => wrapFunctional(c.default || c))
export const ProjectStatusMenu = () => import('../../components/Project/StatusMenu.vue' /* webpackChunkName: "components/project-status-menu" */).then(c => wrapFunctional(c.default || c))
export const ProjectTemplateManager = () => import('../../components/Project/TemplateManager.vue' /* webpackChunkName: "components/project-template-manager" */).then(c => wrapFunctional(c.default || c))
export const ProviderActions = () => import('../../components/Provider/ProviderActions.vue' /* webpackChunkName: "components/provider-actions" */).then(c => wrapFunctional(c.default || c))
export const SearchBucketActions = () => import('../../components/Search/BucketActions.vue' /* webpackChunkName: "components/search-bucket-actions" */).then(c => wrapFunctional(c.default || c))
export const SearchBucketContent = () => import('../../components/Search/BucketContent.vue' /* webpackChunkName: "components/search-bucket-content" */).then(c => wrapFunctional(c.default || c))
export const SearchBucketCounter = () => import('../../components/Search/BucketCounter.vue' /* webpackChunkName: "components/search-bucket-counter" */).then(c => wrapFunctional(c.default || c))
export const SearchBucketPanel = () => import('../../components/Search/BucketPanel.vue' /* webpackChunkName: "components/search-bucket-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterAssignee = () => import('../../components/Search/FilterAssignee.vue' /* webpackChunkName: "components/search-filter-assignee" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterAvailability = () => import('../../components/Search/FilterAvailability.vue' /* webpackChunkName: "components/search-filter-availability" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterChip = () => import('../../components/Search/FilterChip.vue' /* webpackChunkName: "components/search-filter-chip" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterEmail = () => import('../../components/Search/FilterEmail.vue' /* webpackChunkName: "components/search-filter-email" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterExperience = () => import('../../components/Search/FilterExperience.vue' /* webpackChunkName: "components/search-filter-experience" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterExternal = () => import('../../components/Search/FilterExternal.vue' /* webpackChunkName: "components/search-filter-external" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterKeyword = () => import('../../components/Search/FilterKeyword.vue' /* webpackChunkName: "components/search-filter-keyword" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterLocation = () => import('../../components/Search/FilterLocation.vue' /* webpackChunkName: "components/search-filter-location" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterName = () => import('../../components/Search/FilterName.vue' /* webpackChunkName: "components/search-filter-name" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterOwner = () => import('../../components/Search/FilterOwner.vue' /* webpackChunkName: "components/search-filter-owner" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterPhone = () => import('../../components/Search/FilterPhone.vue' /* webpackChunkName: "components/search-filter-phone" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterPool = () => import('../../components/Search/FilterPool.vue' /* webpackChunkName: "components/search-filter-pool" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterPostalRadius = () => import('../../components/Search/FilterPostalRadius.vue' /* webpackChunkName: "components/search-filter-postal-radius" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterProjectLocation = () => import('../../components/Search/FilterProjectLocation.vue' /* webpackChunkName: "components/search-filter-project-location" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterProjectLocation2 = () => import('../../components/Search/FilterProjectLocation2.vue' /* webpackChunkName: "components/search-filter-project-location2" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterRate = () => import('../../components/Search/FilterRate.vue' /* webpackChunkName: "components/search-filter-rate" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterSimple = () => import('../../components/Search/FilterSimple.vue' /* webpackChunkName: "components/search-filter-simple" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterSkills = () => import('../../components/Search/FilterSkills.vue' /* webpackChunkName: "components/search-filter-skills" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterStatus = () => import('../../components/Search/FilterStatus.vue' /* webpackChunkName: "components/search-filter-status" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterTags = () => import('../../components/Search/FilterTags.vue' /* webpackChunkName: "components/search-filter-tags" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterTitle = () => import('../../components/Search/FilterTitle.vue' /* webpackChunkName: "components/search-filter-title" */).then(c => wrapFunctional(c.default || c))
export const SearchFilterVmsId = () => import('../../components/Search/FilterVmsId.vue' /* webpackChunkName: "components/search-filter-vms-id" */).then(c => wrapFunctional(c.default || c))
export const SearchLeftBar = () => import('../../components/Search/LeftBar.vue' /* webpackChunkName: "components/search-left-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchPaginationPanel = () => import('../../components/Search/PaginationPanel.vue' /* webpackChunkName: "components/search-pagination-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchResultsHeader = () => import('../../components/Search/ResultsHeader.vue' /* webpackChunkName: "components/search-results-header" */).then(c => wrapFunctional(c.default || c))
export const SearchResultsPanel = () => import('../../components/Search/ResultsPanel.vue' /* webpackChunkName: "components/search-results-panel" */).then(c => wrapFunctional(c.default || c))
export const SettingsCalendarPanel = () => import('../../components/Settings/CalendarPanel.vue' /* webpackChunkName: "components/settings-calendar-panel" */).then(c => wrapFunctional(c.default || c))
export const SettingsCalendarPicker = () => import('../../components/Settings/CalendarPicker.vue' /* webpackChunkName: "components/settings-calendar-picker" */).then(c => wrapFunctional(c.default || c))
export const SettingsHashMenu = () => import('../../components/Settings/HashMenu.vue' /* webpackChunkName: "components/settings-hash-menu" */).then(c => wrapFunctional(c.default || c))
export const SettingsLanguagePanel = () => import('../../components/Settings/LanguagePanel.vue' /* webpackChunkName: "components/settings-language-panel" */).then(c => wrapFunctional(c.default || c))
export const SettingsLocationsPanel = () => import('../../components/Settings/LocationsPanel.vue' /* webpackChunkName: "components/settings-locations-panel" */).then(c => wrapFunctional(c.default || c))
export const SettingsPasswordPanel = () => import('../../components/Settings/PasswordPanel.vue' /* webpackChunkName: "components/settings-password-panel" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfilePanel = () => import('../../components/Settings/ProfilePanel.vue' /* webpackChunkName: "components/settings-profile-panel" */).then(c => wrapFunctional(c.default || c))
export const SettingsTagManagement = () => import('../../components/Settings/TagManagement.vue' /* webpackChunkName: "components/settings-tag-management" */).then(c => wrapFunctional(c.default || c))
export const TalentCardActionsSearch = () => import('../../components/Talent/CardActionsSearch.vue' /* webpackChunkName: "components/talent-card-actions-search" */).then(c => wrapFunctional(c.default || c))
export const TalentCardPanel = () => import('../../components/Talent/CardPanel.vue' /* webpackChunkName: "components/talent-card-panel" */).then(c => wrapFunctional(c.default || c))
export const TalentDataHarbourTalentDetails = () => import('../../components/Talent/DataHarbourTalentDetails.vue' /* webpackChunkName: "components/talent-data-harbour-talent-details" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsAvailability = () => import('../../components/Talent/DetailsAvailability.vue' /* webpackChunkName: "components/talent-details-availability" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsContact = () => import('../../components/Talent/DetailsContact.vue' /* webpackChunkName: "components/talent-details-contact" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsDesiredRate = () => import('../../components/Talent/DetailsDesiredRate.vue' /* webpackChunkName: "components/talent-details-desired-rate" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsNotSet = () => import('../../components/Talent/DetailsNotSet.vue' /* webpackChunkName: "components/talent-details-not-set" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsPools = () => import('../../components/Talent/DetailsPools.vue' /* webpackChunkName: "components/talent-details-pools" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsSecurityIds = () => import('../../components/Talent/DetailsSecurityIds.vue' /* webpackChunkName: "components/talent-details-security-ids" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsSocialProfiles = () => import('../../components/Talent/DetailsSocialProfiles.vue' /* webpackChunkName: "components/talent-details-social-profiles" */).then(c => wrapFunctional(c.default || c))
export const TalentDetailsWorkPreferences = () => import('../../components/Talent/DetailsWorkPreferences.vue' /* webpackChunkName: "components/talent-details-work-preferences" */).then(c => wrapFunctional(c.default || c))
export const TalentDottedMenu = () => import('../../components/Talent/DottedMenu.vue' /* webpackChunkName: "components/talent-dotted-menu" */).then(c => wrapFunctional(c.default || c))
export const TalentHashMenu = () => import('../../components/Talent/HashMenu.vue' /* webpackChunkName: "components/talent-hash-menu" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryAdminChangedPool = () => import('../../components/Talent/HistoryAdminChangedPool.vue' /* webpackChunkName: "components/talent-history-admin-changed-pool" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryCvEntryAdded = () => import('../../components/Talent/HistoryCvEntryAdded.vue' /* webpackChunkName: "components/talent-history-cv-entry-added" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryCvEntryDeleted = () => import('../../components/Talent/HistoryCvEntryDeleted.vue' /* webpackChunkName: "components/talent-history-cv-entry-deleted" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryCvEntryUpdated = () => import('../../components/Talent/HistoryCvEntryUpdated.vue' /* webpackChunkName: "components/talent-history-cv-entry-updated" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryDeactivation = () => import('../../components/Talent/HistoryDeactivation.vue' /* webpackChunkName: "components/talent-history-deactivation" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryEmail = () => import('../../components/Talent/HistoryEmail.vue' /* webpackChunkName: "components/talent-history-email" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryLabelAdded = () => import('../../components/Talent/HistoryLabelAdded.vue' /* webpackChunkName: "components/talent-history-label-added" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryLabelDeleted = () => import('../../components/Talent/HistoryLabelDeleted.vue' /* webpackChunkName: "components/talent-history-label-deleted" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryLanguageUpdated = () => import('../../components/Talent/HistoryLanguageUpdated.vue' /* webpackChunkName: "components/talent-history-language-updated" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryMspRejectsPool = () => import('../../components/Talent/HistoryMspRejectsPool.vue' /* webpackChunkName: "components/talent-history-msp-rejects-pool" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryPool = () => import('../../components/Talent/HistoryPool.vue' /* webpackChunkName: "components/talent-history-pool" */).then(c => wrapFunctional(c.default || c))
export const TalentHistoryProject = () => import('../../components/Talent/HistoryProject.vue' /* webpackChunkName: "components/talent-history-project" */).then(c => wrapFunctional(c.default || c))
export const TalentJobApplications = () => import('../../components/Talent/JobApplications.vue' /* webpackChunkName: "components/talent-job-applications" */).then(c => wrapFunctional(c.default || c))
export const TalentModalDeactivate = () => import('../../components/Talent/ModalDeactivate.vue' /* webpackChunkName: "components/talent-modal-deactivate" */).then(c => wrapFunctional(c.default || c))
export const TalentModalDelete = () => import('../../components/Talent/ModalDelete.vue' /* webpackChunkName: "components/talent-modal-delete" */).then(c => wrapFunctional(c.default || c))
export const TalentModalHistoryEntry = () => import('../../components/Talent/ModalHistoryEntry.vue' /* webpackChunkName: "components/talent-modal-history-entry" */).then(c => wrapFunctional(c.default || c))
export const TalentModalLanguage = () => import('../../components/Talent/ModalLanguage.vue' /* webpackChunkName: "components/talent-modal-language" */).then(c => wrapFunctional(c.default || c))
export const TalentModalLanguageDelete = () => import('../../components/Talent/ModalLanguageDelete.vue' /* webpackChunkName: "components/talent-modal-language-delete" */).then(c => wrapFunctional(c.default || c))
export const TalentModalPoolDelete = () => import('../../components/Talent/ModalPoolDelete.vue' /* webpackChunkName: "components/talent-modal-pool-delete" */).then(c => wrapFunctional(c.default || c))
export const TalentModalReactivate = () => import('../../components/Talent/ModalReactivate.vue' /* webpackChunkName: "components/talent-modal-reactivate" */).then(c => wrapFunctional(c.default || c))
export const TalentModalSummary = () => import('../../components/Talent/ModalSummary.vue' /* webpackChunkName: "components/talent-modal-summary" */).then(c => wrapFunctional(c.default || c))
export const TalentModalTags = () => import('../../components/Talent/ModalTags.vue' /* webpackChunkName: "components/talent-modal-tags" */).then(c => wrapFunctional(c.default || c))
export const TalentModalTier = () => import('../../components/Talent/ModalTier.vue' /* webpackChunkName: "components/talent-modal-tier" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewAttachments = () => import('../../components/Talent/OverviewAttachments.vue' /* webpackChunkName: "components/talent-overview-attachments" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewBulkTagInput = () => import('../../components/Talent/OverviewBulkTagInput.vue' /* webpackChunkName: "components/talent-overview-bulk-tag-input" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewErrorIndicator = () => import('../../components/Talent/OverviewErrorIndicator.vue' /* webpackChunkName: "components/talent-overview-error-indicator" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewHeader = () => import('../../components/Talent/OverviewHeader.vue' /* webpackChunkName: "components/talent-overview-header" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewHeaderMicro = () => import('../../components/Talent/OverviewHeaderMicro.vue' /* webpackChunkName: "components/talent-overview-header-micro" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewHistoryEntries = () => import('../../components/Talent/OverviewHistoryEntries.vue' /* webpackChunkName: "components/talent-overview-history-entries" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewLanguages = () => import('../../components/Talent/OverviewLanguages.vue' /* webpackChunkName: "components/talent-overview-languages" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewLastApplications = () => import('../../components/Talent/OverviewLastApplications.vue' /* webpackChunkName: "components/talent-overview-last-applications" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewNotes = () => import('../../components/Talent/OverviewNotes.vue' /* webpackChunkName: "components/talent-overview-notes" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewPendingApps = () => import('../../components/Talent/OverviewPendingApps.vue' /* webpackChunkName: "components/talent-overview-pending-apps" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewRecruiterTools = () => import('../../components/Talent/OverviewRecruiterTools.vue' /* webpackChunkName: "components/talent-overview-recruiter-tools" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewSection = () => import('../../components/Talent/OverviewSection.vue' /* webpackChunkName: "components/talent-overview-section" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewSummary = () => import('../../components/Talent/OverviewSummary.vue' /* webpackChunkName: "components/talent-overview-summary" */).then(c => wrapFunctional(c.default || c))
export const TalentOverviewTags = () => import('../../components/Talent/OverviewTags.vue' /* webpackChunkName: "components/talent-overview-tags" */).then(c => wrapFunctional(c.default || c))
export const TalentPanelDetails = () => import('../../components/Talent/PanelDetails.vue' /* webpackChunkName: "components/talent-panel-details" */).then(c => wrapFunctional(c.default || c))
export const TalentPanelHistory = () => import('../../components/Talent/PanelHistory.vue' /* webpackChunkName: "components/talent-panel-history" */).then(c => wrapFunctional(c.default || c))
export const TalentPanelOverview = () => import('../../components/Talent/PanelOverview.vue' /* webpackChunkName: "components/talent-panel-overview" */).then(c => wrapFunctional(c.default || c))
export const TalentPipelineMenu = () => import('../../components/Talent/PipelineMenu.vue' /* webpackChunkName: "components/talent-pipeline-menu" */).then(c => wrapFunctional(c.default || c))
export const TalentReasonModal = () => import('../../components/Talent/ReasonModal.vue' /* webpackChunkName: "components/talent-reason-modal" */).then(c => wrapFunctional(c.default || c))
export const I18n = () => import('../../components/I18n/i18n.vue' /* webpackChunkName: "components/i18n" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
