export default (axios) => ({
  acknowledgedTask(uuid) {
    return axios.put(`/api/tasks/${uuid}/acknowledge`)
  },
  rejectPendingAsyncBulk(body) {
    return axios.post(`/api/projectApplication/rejectPending`, body)
  },
  getTasks() {
    return axios.get(`/api/tasks/now`)
  }
})
