
export default {
  name: 'AppTabResume',

  data: () => ({ isLoading: true }),

  mounted() {
    window.addEventListener('resize', this.adjustHeight)
    this.$nextTick(this.adjustHeight)
  },

  destroyed() {
    window.removeEventListener('resize', this.adjustHeight)
  },

  methods: {
    onLoadedData() {
      this.isLoading = false
    },

    adjustHeight() {
      const pdfViewerContainer = this.$refs.pdfViewerContainer
      const toolbar = document.querySelector('#appBar')
      const toolbarHeight = parseInt(document.defaultView.getComputedStyle(toolbar).height)

      pdfViewerContainer.style.height = `${window.innerHeight - toolbarHeight}px`
    }
  }
}
