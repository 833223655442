
import { mapGetters } from 'vuex'
import { cloneDeep, flatten, intersection } from 'lodash-es'
import { EApplicationTab, EJobApplicationStatus } from '@/helpers/enums'

export default {
  name: 'AppTabScreening',

  computed: {
    ...mapGetters({
      application: 'application/application',
      questions: 'application/questions',
      pendingPools: 'talent/pendingTalentPools',
      projectPools: 'project/projectPools'
    }),

    hasPendingProjectPoolsApplications() {
      const projectPoolsIds = this.projectPools?.map(({ id }) => id) ?? []
      const talentPendingPoolsIds = this.pendingPools.map(({ id }) => id)

      return Boolean(intersection(projectPoolsIds, talentPendingPoolsIds).length)
    },

    questionHierarchy() {
      const hierarchy = []
      const questions = cloneDeep(this.questions)

      for (const question of questions) {
        if (question.parentQuestion === null || typeof question.parentQuestion === 'undefined') {
          hierarchy.push(question)
        } else {
          const parent = questions.find(
            (q) => q.id === question.parentQuestion || q.componentName === question.parentQuestion
          )

          if (parent) {
            if (Array.isArray(parent.children)) {
              parent.children.push(question)
              parent.children.sort((a, b) => a.position - b.position)
            } else {
              parent.children = [question]
            }
          }
        }
      }

      return hierarchy
    }
  },

  watch: {
    '$route.query': {
      immediate: true,
      handler({ screeningInvalid, tab }) {
        if (tab === EApplicationTab.SCREENING && screeningInvalid) {
          this.validate()
        }
      }
    }
  },

  methods: {
    isQuestionDisabled(question) {
      return (
        this.hasPendingProjectPoolsApplications ||
        this.application.status !== EJobApplicationStatus.ACTIVE ||
        !question.mspCanEdit
      )
    },

    validate() {
      const getValidatorsRecursive = (questionWrapper) => {
        const questionValidator = questionWrapper.$refs.question.$refs.card.$refs.validator
        const children = questionWrapper.$refs.children
        const childrenValidators = flatten(children?.map((ch) => getValidatorsRecursive(ch)) ?? [])

        return [questionValidator, ...childrenValidators]
      }

      this.$nextTick(async () => {
        const validators = flatten(this.$refs.questions.map((q) => getValidatorsRecursive(q)))
        const validationResults = await Promise.allSettled(
          validators.map((v) => v.validateWithInfo())
        )

        validationResults.every(({ value }, idx) => {
          if (value.isValid) {
            return true
          }

          // CHECK
          // @TODO
          // This one doesn't work when switching between tabs
          // It's because the tab content is not rendered yet
          // Even if it's monuted
          this.$scrollToInvalid(validators[idx], value.errors)

          return false
        })
      })
    }
  }
}
