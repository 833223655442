import { del, set } from 'vue'
import { flatten, get, orderBy } from 'lodash-es'
import { EFlowShowOn } from '@twago/flows'
import { EFreelancerStatus, EPoolMembershipStatus } from '@/helpers/enums'
import { transformLog, transformPool, transformProject } from '@/helpers/talent'

export const state = () => ({
  talentData: null,
  talentPools: [],
  talentProfile: [],
  clientTags: [],
  totalClientTags: 0,
  tags: [],
  totalTags: 0,
  notes: [],
  totalNotes: 0,
  history: []
})

export const actions = {
  async fetchTalentData({ commit }, talentId) {
    const { data } = await this.$api.freelancer.getWithJobApplications(talentId)
    commit('SET_TALENT_DATA', data)
  },

  async fetchTalentProfile({ commit }, talentId) {
    const { data } = await this.$api.freelancer.getProfile(talentId)
    commit('SET_TALENT_PROFILE', data)
  },

  async fetchPools({ commit }, talentId) {
    try {
      const { data } = await this.$api.freelancer.getMembershipBulk(talentId)
      commit('SET_TALENT_POOLS', data.results)
    } catch (e) {}
  },

  async fetchClientTags({ commit }) {
    try {
      const { data } = await this.$api.tag.getBulk()
      commit('SET_CLIENT_TAGS', data.results)
      commit('SET_TOTAL_CLIENT_TAGS', data.totalResults)
    } catch (e) {}
  },

  async fetchTags({ commit }, talentId) {
    try {
      const { data } = await this.$api.tag.get(talentId)
      commit('SET_TAGS', data.results)
      commit('SET_TOTAL_TAGS', data.totalResults)
    } catch (e) {}
  },

  async fetchNotes({ commit }, talentId) {
    try {
      const { data } = await this.$api.actionLog.getBulk(talentId)
      commit('SET_NOTES', data.results)
      commit('SET_TOTAL_NOTES', data.totalResults)
    } catch (e) {}
  },

  async fetchHistory({ commit }, talentId) {
    try {
      const logsPromise = this.$api.freelancer
        .getHistoryBulk(talentId, { from: 0, size: 1000 })
        .then(({ data: { results } }) => results.map(transformLog))

      const poolsPromise = this.$api.es
        .talentQuery({
          query: `id=${talentId}`,
          filters: {},
          size: 1,
          offset: 0,
          sort: [{ name: 'pools.membershipCreated', dir: 'desc' }]
        })
        .then(
          ({
            data: {
              results: [talent]
            }
          }) => talent?.pools?.map((pool) => transformPool(pool, talent)) ?? []
        )

      const projectsPromise = this.$api.es
        .applicationQuery({
          query: `freelancer_id=${talentId}`,
          size: 999,
          offset: 0,
          sort: [{ name: 'created', dir: 'desc' }]
        })
        .then(({ data: { results } }) => results.map(transformProject))

      const historyEntries = orderBy(
        flatten(await Promise.all(flatten([logsPromise, poolsPromise, projectsPromise]))),
        ['created'],
        'desc'
      )

      commit('SET_HISTORY', historyEntries)
    } catch (e) {}
  },

  updateTalentData({ commit }, payload) {
    commit('SET_TALENT_DATA', payload)
  },

  deleteTag({ commit, state }, tagId) {
    const index = state.tags.findIndex((t) => t.id === tagId)

    if (~index) {
      commit('DELETE_TAG', index)
      commit('DECREMENT_TOTAL_TAGS')
    }
  },

  addNote({ commit }, payload) {
    commit('ADD_NOTE', payload)
  },

  updateNote({ commit, state }, { id, data }) {
    const index = state.notes.findIndex((n) => n.id === id)

    if (~index) {
      commit('UPDATE_NOTE', { index, note: data })
    }
  },

  deleteNote({ commit, state }, id) {
    const index = state.notes.findIndex((n) => n.id === id)

    if (~index) {
      commit('DELETE_NOTE', index)
      commit('DECREMENT_TOTAL_NOTES')
    }
  },

  async updateProfile({ dispatch }, { id, payload }) {
    const response = await this.$api.freelancer.updateProfile(id, payload)
    await Promise.all([dispatch('fetchTalentProfile', id), dispatch('fetchHistory', id)])

    return response
  }
}

export const mutations = {
  SET_TALENT_DATA: (state, payload) => {
    state.talentData = payload
  },

  SET_TALENT_PROFILE: (state, payload) => {
    state.talentProfile = payload
  },

  SET_TALENT_POOLS: (state, payload) => {
    state.talentPools = payload
  },

  SET_CLIENT_TAGS: (state, tags) => {
    state.clientTags = tags
  },

  SET_TOTAL_CLIENT_TAGS: (state, totalTags) => {
    state.totalClientTags = totalTags
  },

  SET_TAGS: (state, tags) => {
    state.tags = tags
  },

  SET_TOTAL_TAGS: (state, totalTags) => {
    state.totalTags = totalTags
  },

  SET_NOTES: (state, notes) => {
    state.notes = notes
  },

  SET_TOTAL_NOTES: (state, totalNotes) => {
    state.totalNotes = totalNotes
  },

  ADD_TAG: (state, tag) => {
    state.tags.unshift(tag)
  },

  DELETE_TAG: (state, index) => {
    del(state.tags, index)
  },

  DECREMENT_TOTAL_TAGS: (state) => {
    state.totalTags--
  },

  ADD_NOTE: (state, note) => {
    state.notes.unshift(note)
    state.totalNotes++
  },

  DELETE_NOTE: (state, index) => {
    del(state.notes, index)
  },

  DECREMENT_TOTAL_NOTES: (state) => {
    state.totalNotes--
  },

  UPDATE_NOTE: (state, { index, note }) => {
    set(state.notes, index, note)
  },

  SET_HISTORY: (state, history) => {
    state.history = history
  }
}

export const getters = {
  talentData: ({ talentData }) => talentData,

  talentProfile: ({ talentProfile }) => talentProfile,

  talentPools: ({ talentPools }) => talentPools,

  pendingTalentPools: ({ talentPools }) =>
    talentPools?.filter(({ status }) => status === EPoolMembershipStatus.PENDING),

  talentId: ({ talentData }) => talentData.id,

  isActive: ({ talentData }) => talentData.state === EFreelancerStatus.ACTIVE,

  clientTags: ({ clientTags }) => clientTags,

  tags: ({ tags }) => tags,

  totalTags: ({ totalTags }) => totalTags,

  notes: ({ notes }) => notes,

  totalNotes: ({ totalNotes }) => totalNotes,

  cvId: ({ talentData }) => talentData.cvId,

  // prettier-ignore
  getClientTagById: ({ clientTags }) => (tagId) => clientTags?.find((t) => t.id === tagId),

  history: ({ history }) => history,

  applications: ({ talentData }) => talentData?.applications ?? [],

  getPanelConfig:
    ({ talentProfile }) =>
    (panelName) =>
      talentProfile?.find(({ name }) => name === panelName) ?? {},

  isPanelVisible:
    ({ talentData, talentProfile }) =>
    (panelName) => {
      const panelConfig = talentProfile?.find(({ name }) => name === panelName) ?? {}
      const talentCountry = talentData?.homeAddressCountry
      const showOn = get(panelConfig, 'showOn', [])
      const hideByCountry = get(panelConfig, 'options.hideByCountry', [])

      const includedByShowOn = showOn.includes(EFlowShowOn.PROFILE_MSP)
      const hiddenByCountry = hideByCountry.includes(talentCountry)

      return includedByShowOn && !hiddenByCountry
    }
}
