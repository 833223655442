
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'BaseNoteEdit',

  components: { ValidationObserver },

  props: {
    types: {
      type: Array,
      required: true
    },

    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    note: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('value', val)
      }
    }
  },

  methods: {
    async handleSaveBtnClick() {
      this.$emit((await this.$refs.validator.validate()) ? 'save' : 'validation-error')
    },

    toggleType(type) {
      this.$set(this.note, 'actionType', this.note.actionType === type ? null : type)
    }
  }
}
