import { EAuthType } from '@/helpers/enums'

export const settingsMenu = (ctx) => [
  {
    id: 'account',
    title: ctx.$t('msp.v2.settings.account'),
    display: true,
    subList: [
      {
        id: 'profile',
        title: ctx.$t('msp.v2.settings.profile'),
        to: '#profile',
        display: true
      },
      {
        id: 'language',
        title: ctx.$t('msp.v2.settings.language'),
        to: '#language',
        display: true
      },
      {
        id: 'password',
        title: ctx.$t('msp.v2.settings.password'),
        to: '#password',
        display: ctx.$auth?.user?.authType === EAuthType.PASSWORD
      }
    ]
  },
  {
    id: 'workflow',
    title: ctx.$t('msp.v2.settings.workflow'),
    display: true,
    subList: [
      {
        id: 'tags',
        title: ctx.$t('msp.v2.settings.tag.management'),
        to: '#tags',
        display: true
      },
      {
        id: 'questions',
        title: ctx.$t('msp.v2.settings.questions'),
        to: '#questions',
        display: ctx.$useConfig(ctx.$EClientConfig.ENABLE_PAQ)
      },
      {
        id: 'locations',
        title: ctx.$t('msp.v2.settings.locations'),
        to: '#locations',
        display: true
      }
    ]
  },
  {
    id: 'integrations',
    title: ctx.$t('msp.v2.settings.integrations'),
    display: ctx.calendarEnabled,
    subList: [
      {
        id: 'calendar',
        title: ctx.$t('msp.v2.settings.google.calendar'),
        to: '#calendar',
        display: true
      }
    ]
  }
]
