export default ({ $cmsApiCall, $fallbackLangs, store }) => {
  return {
    getCategories(
      fields = [
        'id',
        'translations.language_code',
        'translations.name',
        'translations.description'
      ],
      filter = {}
    ) {
      return $cmsApiCall()
        .items('d_category')
        .readByQuery({
          filter: {
            client: { client_name: { _eq: store.state.clientContext.name } },
            status: 'published',
            ...filter
          },
          fields,
          deep: {
            translations: {
              _filter: {
                _or: $fallbackLangs().map((code) => ({ language_code: { _eq: code } }))
              }
            }
          },
          limit: -1
        })
    }
  }
}
