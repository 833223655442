import { EHistoryPage } from '@/helpers/enums'

export default function ({ from, route, store }) {
  store.commit('LEAVE_EMPTY', !!from)

  const poolsScope = [
    EHistoryPage.POOL,
    EHistoryPage.POOL_TALENTS,
    EHistoryPage.POOL_PROJECTS
  ].includes(route?.name)

  const fromXToPoolsScope = [
    EHistoryPage.POOL,
    EHistoryPage.POOL_TALENTS,
    EHistoryPage.POOL_PROJECTS,
    EHistoryPage.TALENT_ID,
    EHistoryPage.PROJECT_ID_STATUS_LABELS_LABELID,
    EHistoryPage.PROJECT_ID_STATUS,
    EHistoryPage.PROJECT_ID_SUMMARY,
    EHistoryPage.MANAGE_PROJECT_ID_UNPUBLISHED,
    EHistoryPage.MANAGE_PROJECT_ID_EDIT
  ].includes(from?.name)

  const projectScope = [
    EHistoryPage.PROJECT_ID_STATUS_LABELS_LABELID,
    EHistoryPage.PROJECT_ID_STATUS,
    EHistoryPage.PROJECT_ID_SUMMARY
  ].includes(route?.name)

  const fromXToProjectScope = [
    EHistoryPage.DASHBOARD_PROJECTS,
    EHistoryPage.SEARCH_PROJECTS,
    EHistoryPage.POOL_PROJECTS
  ].includes(from?.name)

  if (from && route.name === EHistoryPage.TALENT_ID && route?.hash === '') {
    store.commit('LEAVE_TALENT', { name: from.name, path: from.path, fullPath: from.fullPath })
    // console.log('CAME TO TALENT PAGE', JSON.stringify(store.state.leaveTalent, null, 3))
  }

  if (from && projectScope && fromXToProjectScope) {
    store.commit('LEAVE_PROJECT', { name: from.name, path: from.path, fullPath: from.fullPath })
    // console.log('CAME TO PROJECT PAGE', JSON.stringify(store.state.leaveProject, null, 3))
  }

  if (from && poolsScope && !fromXToPoolsScope) {
    store.commit('LEAVE_POOL', { name: from.name, path: from.path, fullPath: from.fullPath })
    // console.log('CAME TO POOL PAGE', JSON.stringify(store.state.leavePool, null, 3))
  }
}
