import { render, staticRenderFns } from "./NotesPanel.vue?vue&type=template&id=1d78f300&"
import script from "./NotesPanel.vue?vue&type=script&lang=js&"
export * from "./NotesPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseNoteEdit: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/NoteEdit.vue').default,BaseNotePreview: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/NotePreview.vue').default,BaseModalTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/ModalTemplate.vue').default,BaseNoResults: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/NoResults.vue').default})
