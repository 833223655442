import jwtDecode from 'jwt-decode'

export async function setupPermissions(auth) {
  if (!auth.loggedIn) {
    return
  }

  const accessToken = auth.strategy.token.get()
  const rawTokenData = accessToken.split(' ')[1]
  const tokenData = jwtDecode(rawTokenData)

  await auth.setUser({
    ...auth.user,
    permissions: tokenData.$int_perms || []
  })
}
