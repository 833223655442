import { ETalentPanel, ETalentAttachmentType } from '@twago/flows'
import { EApplicationTab } from '@/helpers/enums'

import TabAttachments from '@/components/App/TabAttachments'
import TabNotes from '@/components/App/TabNotes'
import TabResume from '@/components/App/TabResume'
import TabScreening from '@/components/App/TabScreening'

export function applicationTabMenu(ctx) {
  return [
    {
      id: 'resume',
      ref: 'resume',
      value: EApplicationTab.RESUME,
      label: ctx.$t('msp.v2.application.resume'),
      component: TabResume,
      wrapWithContainer: false
    },
    {
      id: 'screening',
      ref: 'screening',
      value: EApplicationTab.SCREENING,
      label: ctx.$t('msp.v2.application.screening'),
      component: TabScreening,
      condition: ctx.$store.getters['application/questions']?.length,
      on: {
        'question-updated'() {
          const resumeReference = ctx.$refs.resume?.[0]

          if (resumeReference) {
            try {
              resumeReference.isLoading = true
              resumeReference.$refs.pdfViewer.contentWindow.location.reload()
            } catch (e) {
              resumeReference.isLoading = false
            }
          }
        }
      }
    },
    {
      id: 'notes',
      ref: 'notes',
      value: EApplicationTab.NOTES,
      label: ctx.$t('msp.v2.application.notes'),
      icon: '$vuetify.icons.note',
      component: TabNotes,
      attrs: {
        isApplicationNote: true
      }
    },
    {
      id: 'attachments',
      ref: 'attachents',
      value: EApplicationTab.ATTACHMENTS,
      label: ctx.$t('msp.v2.application.attachments'),
      component: TabAttachments,
      condition: [ETalentPanel.CERTIFICATES, ETalentPanel.EDUCATION, ETalentPanel.WORK_EXPERIENCE]
        .filter(ctx.$store.getters['talent/isPanelVisible'])
        .map(ctx.$store.getters['talent/getPanelConfig'])
        .some(
          (panel) =>
            panel.form.some((field) => field.name === ETalentAttachmentType.HISTORY) &&
            Array.isArray(panel.value) &&
            panel.value.some((val) => val[ETalentAttachmentType.HISTORY])
        )
    }
  ]
}
