export default (axios) => ({
  getAssignments(projectId) {
    return axios.get(`/api/project/${projectId}/assignments`)
  },

  getBulk() {
    return axios.get('/api/msp/listAllForClient')
  },

  getForJob(jobHandle) {
    return axios.get(`/api/msps/forJob/${jobHandle}`)
  },

  update(body) {
    return axios.put('/api/msp', body)
  },

  disconnectCalendar() {
    return axios.put('/api/msp/calendar/disconnect')
  },

  listCalendars() {
    return axios.get('/api/msp/calendar')
  },

  saveCalendarToken(code) {
    return axios.post('/api/msp/calendar/token', { code })
  },

  selectCalendar(calendarId) {
    return this.update({ calendarId })
  }
})
