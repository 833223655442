export default (axios) => ({
  contact(body) {
    return axios.post('/api/freelancer/contact', body)
  },

  create(body) {
    return axios.post('/api/freelancer/register', body)
  },

  getProfile(freelancerId) {
    return axios.get(`/api/freelancer/${freelancerId}/profile`)
  },

  updateProfile(freelancerId, data) {
    return axios.patch(`/api/freelancer/${freelancerId}/profile`, data)
  },

  getWithJobApplications(freelancerId) {
    return axios.get(`/api/freelancer/${freelancerId}/projectApplications`)
  },

  update(freelancerId) {
    return axios.put(`/api/freelancer/${freelancerId}`)
  },

  delete(freelancerId, data) {
    return axios.delete(`/api/freelancer/${freelancerId}`, { data })
  },

  getCv(freelancerId) {
    return axios.get(`/api/freelancer/${freelancerId}/cv/pdf`)
  },

  getHistoryBulk(freelancerId, query) {
    return axios.get(`/api/freelancer/${freelancerId}/history`, {
      params: {
        ...query
      }
    })
  },

  activate(freelancerId) {
    return axios.put(`/api/freelancer/${freelancerId}/activate`)
  },

  deactivate(freelancerId, reason) {
    return axios.put(`/api/freelancer/${freelancerId}/deactivate`, { ...reason })
  },

  // FIXME: use this api only on talent profile to show pools that need to be accepted
  getMembershipBulk(freelancerId, query) {
    return axios.get(`/api/freelancer/${freelancerId}/poolMemberships`, {
      params: {
        ...query
      }
    })
  },

  updateTier(freelancerId, body) {
    return axios.put(`/api/freelancer/${freelancerId}/tier`, body)
  },

  deleteTier(freelancerId) {
    return axios.delete(`/api/freelancer/${freelancerId}/tier`)
  },

  getActionLogs(freelancerId) {
    return axios.get(`/api/freelancer/${freelancerId}/actionLog`)
  },

  getActionLog(freelancerId, noteId) {
    return axios.get(`/api/freelancer/${freelancerId}/actionLog/${noteId}`)
  },

  createActionLog(freelancerId, body) {
    return axios.post(`/api/freelancer/${freelancerId}/actionLog`, body)
  },

  updateActionLog(freelancerId, actionLogId, body) {
    return axios.put(`/api/freelancer/${freelancerId}/actionLog/${actionLogId}`, body)
  },

  deleteActionLog(freelancerId, actionLogId) {
    return axios.delete(`/api/freelancer/${freelancerId}/actionLog/${actionLogId}`)
  },

  getOtherApplications(ids) {
    return axios.post('/api/freelancer/projectApplications', { ids })
  }
})
