export const EApplicationTab = Object.freeze({
  ATTACHMENTS: 'attachments',
  RESUME: 'resume',
  NOTES: 'notes',
  SCREENING: 'screening'
})

export const EManualLogType = Object.freeze({
  NOTE: 'mln',
  INTERVIEW: 'mli',
  CALL: 'mlc'
})

export const EPendingPoolApplicationFlowAction = Object.freeze({
  ACCEPT_SELECTED: 'ACCEPT_SELECTED',
  ACCEPT_SELECTED_AND_REJECT_UNSELECTED: 'ACCEPT_SELECTED_AND_REJECT_UNSELECTED',
  REJECT_SELECTED: 'REJECT_SELECTED'
})

export const EPendingPoolApplicationFlowStage = Object.freeze({
  START: 'START',
  CONFIRM: 'CONFIRM',
  REJECT: 'REJECT'
})

export const EScreeningQuestionType = Object.freeze({
  CURRENCY: 'currency',
  DATE: 'date',
  DROPDOWN: 'dropdown',
  NUMBER: 'number',
  RADIO: 'radio',
  TEXTAREA: 'text',
  TEXT: 'textField',
  CHECKBOX: 'checkbox'
})

export const EWorkModelType = Object.freeze({
  HYBRID: 'hy',
  ONSITE: 'on',
  REMOTE: 'rm'
})
