export const EBooleanSearch = Object.freeze({
  EQ: 'EQ',
  NEQ: 'NEQ',
  LIKE: 'LIKE'
})

export const EFilter = Object.freeze({
  ASSIGNED_MSP_ID: 'assigned_msp_id',
  UNASSIGNED: 'is_unassigned',
  POOLS: 'pools',
  POOL_ID: 'pool_id',
  POOL_STAGE: 'pool_stage',
  VMS_JOB_ID: 'vms_id',
  PROJECT_TITLE: 'title',
  STATUS: 'status',
  NAME: 'name',
  TALENT_NAME: 'talentName',
  EXPERIENCE: 'previous_job',
  SKILL: 'skill',
  EMAIL: 'email',
  AVAILABLE: 'available',
  RATE_RANGE: 'rateRange',
  TAG: 'tag',
  LOCATION: 'location',
  HOME_COUNTRY: 'country',
  COUNTRY: 'country',
  CITY: 'city',
  POSTAL_RADIUS: 'postalcoderadius',
  KEYWORD: 'keyword',
  ANY: 'any',
  PAGE: 'page',
  SELECTED_ITEMS: 'selectedItems',
  POOLS_IDX: 'poolsIdx',
  SIMPLE_QUERY: 'simpleQuery',
  TAB: 'tab',
  SORT_BY: 'sortBy',
  PHONE: 'phone',
  OWNER_ID: 'owner_id'
})

export const ESearchProvider = Object.freeze({
  MONSTER: 'monster',
  TEXTKERNEL: 'textkernel'
})

export const ESearchType = Object.freeze({
  TALENT: 'talents',
  PROJECT: 'projects'
})

export const ESelectionMethod = Object.freeze({
  SELECTION: 'selection',
  BUCKET: 'bucket'
})

export const EProjectSorting = Object.freeze({
  LAST_UPDATED: 'last_updated',
  NEW_APPLICATIONS: 'new_application',
  DEADLINE: 'hiring_deadline'
})
