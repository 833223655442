import Cookie from 'cookie'
import JsCookie from 'js-cookie'
import { localize } from 'vee-validate'
import { defineShortLocale } from '@twago/configs'

export const COOKIE_I18N = 'i18n_redirected'

function validateLocaleCookie(locale, store) {
  return (
    typeof locale === 'string' &&
    locale.match(/^[a-z]{2}(-[A-Z]{2}){0,1}$/) &&
    store.state.clientContext.languages.map((e) => e.iso).includes(locale)
  )
}

export function readLocaleCookie({ req, store }) {
  const getValidLocale = (locale) => (validateLocaleCookie(locale, store) ? locale : undefined)

  if (process.server) {
    const getLangCookie = Cookie.parse(req.headers?.cookie || COOKIE_I18N)
    return getValidLocale(getLangCookie[COOKIE_I18N])
  }

  const getLangCookie = JsCookie.get(COOKIE_I18N)
  return getValidLocale(getLangCookie)
}

function setLocaleCookie(locale, res) {
  const date = new Date()
  const cookieOptions = {
    expires: new Date(date.setDate(date.getDate() + 365)),
    path: '/',
    sameSite: 'lax'
  }
  const headers = res.getHeader('Set-Cookie') || []
  const redirectCookie = Cookie.serialize(COOKIE_I18N, locale, cookieOptions)

  headers.push(redirectCookie)

  res.setHeader('Set-Cookie', headers)
}

async function initLocale({ app, req, res, store }) {
  let locale = readLocaleCookie({ req, store })

  if (!(locale && validateLocaleCookie(locale, store))) {
    locale = store.state.clientContext.languages.find((e) => e.primary).iso
    setLocaleCookie(locale, res)
  }

  const params = { client: store.state.clientContext.name }
  const { data } = await app.$axios.get(`/napi/localize/${locale}`, { params })

  store.commit('SET_LOCALE', locale)
  store.commit('SET_MESSAGES', data)
}

async function localizeValidation(code) {
  const useShort = defineShortLocale(code)
  const useShortCode = ['ua-UA'].includes(code) ? 'en' : useShort
  const locale = await import(`vee-validate/dist/locale/${useShortCode}.json`)
  localize(useShort, locale)
}

function interpolateMessage(translatedMessage, values) {
  return Object.entries(values).reduce(
    (acc, [slot, value]) => acc.replace(`{${slot}}`, value),
    translatedMessage
  )
}

export default async function ({ app, req, res, store }, inject) {
  if (process.server) {
    await initLocale({ app, req, res, store })
  }

  const { locale, messages } = store.state
  await localizeValidation(locale)

  const i18n = {
    t: (key, values) => {
      const translation = messages.get(key)

      if (!translation) return key
      if (!values) return translation

      return interpolateMessage(translation, values)
    },

    tc: (key, choice, values) => {
      const translation = messages.get(key)

      if (!translation) return key

      const choices = translation.split('|')
      const choiceIndex = choice === undefined || choice === 1 ? 0 : 1 // FIXME implement per-language pluralization when actually needed
      const chosenMessage = choices[choiceIndex] || choices[0]

      // prettier-ignore
      return values
        ? interpolateMessage(chosenMessage, values)
        : chosenMessage
    },

    te: (key) => messages.has(key),

    get locale() {
      return store.state.locale
    },

    set locale(value) {
      if (!validateLocaleCookie(value, store)) throw new Error('Invalid locale')

      store.commit('SET_LOCALE', value)
      JsCookie.set(COOKIE_I18N, value)
      window.location.reload()
    }
  }

  inject('i18n', i18n)
  inject('t', i18n.t)
  inject('tc', i18n.tc)
  inject('te', i18n.te)
}
