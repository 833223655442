
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FormFieldInput',

  components: { ValidationProvider },

  inheritAttrs: false,

  props: {
    customMessages: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: null
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    persistentHint: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    textFieldValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
