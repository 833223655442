// eslint camelcase: off

import { extend } from 'vee-validate'
import {
  required,
  email,
  alpha,
  min,
  max,
  confirmed,
  numeric,
  oneOf,
  regex,
  integer,
  size,
  ext,
  is,
  between,
  min_value as minValue,
  max_value as maxValue
} from 'vee-validate/dist/rules'

extend('required', {
  ...required
})

extend('email', {
  ...email
})

extend('regex', {
  ...regex
})

extend('alpha', {
  ...alpha
})

extend('min', {
  ...min
})

extend('max', {
  ...max
})

extend('confirmed', {
  ...confirmed
})

extend('numeric', {
  ...numeric
})

extend('oneOf', {
  ...oneOf
})

extend('integer', {
  ...integer
})

extend('size', {
  ...size
})

extend('ext', {
  ...ext
})

extend('between', {
  ...between
})

extend('minValue', {
  ...minValue
})

extend('maxValue', {
  ...maxValue
})

extend('is', {
  ...is
})

extend('valChars', (value) => {
  if (value.match(/(?=.*[!@#$%^&*])/)) {
    return true
  }

  return 'msp.v2.pwd.error.characters'
})

extend('valCapitals', (value) => {
  if (value.match(/(?=.*[A-Z])/)) {
    return true
  }

  return 'msp.v2.pwd.error.capitals'
})

extend('valLowers', (value) => {
  if (value.match(/(?=.*[a-z])/)) {
    return true
  }

  return 'msp.v2.pwd.error.lowers'
})

extend('valDigits', (value) => {
  if (value.match(/(?=.*[0-9])/)) {
    return true
  }

  return 'msp.v2.pwd.error.digits'
})
