import { makeInstance, getTranslation, getImage, getFallbackLangs } from '@twago/cms'

export default ({ $config, app, store }, inject) => {
  const currentLocale = app.$i18n.locale
  const { languages } = store.state.clientContext

  inject('cmsApiCall', () => makeInstance($config.cmsApiUrl, $config.cmsApiToken))

  inject('cmsImage', (item) => getImage(item, currentLocale))

  inject('cmsTranslation', (item, fieldName) =>
    getTranslation(item, fieldName, currentLocale, languages)
  )

  inject('fallbackLangs', () => getFallbackLangs(currentLocale, languages))
}
