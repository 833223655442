import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ee75c172&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSystemTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Layout/SystemTemplate.vue').default,BasePollingTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/PollingTemplate.vue').default,BaseMobileMenu: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/MobileMenu.vue').default,BaseSearchField: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/SearchField.vue').default,BaseAvatarTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/AvatarTemplate.vue').default,BaseUserMenu: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/UserMenu.vue').default,LayoutAnnouncementTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Layout/AnnouncementTemplate.vue').default})
