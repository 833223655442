
export default {
  name: 'BaseNotesPanel',

  props: {
    createNoteFn: {
      type: Function,
      required: true
    },

    deleteNoteFn: {
      type: Function,
      required: true
    },

    editionTransformerFn: {
      type: Function,
      default: null
    },

    notes: {
      type: Array,
      default: () => []
    },

    previewTransformerFn: {
      type: Function,
      required: true
    },

    types: {
      type: Array,
      required: true
    },

    updateNoteFn: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      adding: false,
      editing: false,
      isLoading: false,
      selectedNote: this.makeEmptyNote(),
      selectedNoteId: null,
      deleting: false
    }
  },

  methods: {
    async createNote() {
      try {
        this.isLoading = true
        await this.createNoteFn(this.selectedNote)
      } finally {
        this.adding = false
        this.isLoading = false
        this.selectedNote = this.makeEmptyNote()
      }
    },

    async deleteNote() {
      try {
        this.isLoading = true
        await this.deleteNoteFn(this.selectedNoteId)
      } finally {
        this.deleting = false
        this.isLoading = false
      }
    },

    async updateNote() {
      try {
        this.isLoading = true
        await this.updateNoteFn(this.selectedNoteId, this.selectedNote)
      } finally {
        this.editing = false
        this.isLoading = false
        this.selectedNoteId = null
        this.selectedNote = this.makeEmptyNote()
      }
    },

    handleDeleteModalClose() {
      this.selectedNoteId = null
    },

    makeEmptyNote() {
      return {
        actionType: null,
        executionDate: '',
        executionDateDate: new Date(),
        note: null
      }
    },

    requestDelete(noteId) {
      this.selectedNoteId = noteId
      this.deleting = true
    },

    requestEdit(note, noteId) {
      this.toggleEditing()
      this.selectedNoteId = noteId
      this.selectedNote =
        typeof this.editionTransformerFn === 'function'
          ? this.editionTransformerFn(note)
          : { ...note }
    },

    cancelAdding() {
      this.toggleAdding()
      this.selectedNoteId = null
    },

    cancelEditing() {
      this.toggleEditing()
      this.selectedNoteId = null
      this.selectedNote = this.makeEmptyNote()
    },

    toggleAdding() {
      this.adding = !this.adding
    },

    toggleEditing() {
      this.editing = !this.editing
    }
  }
}
