import { ETalentPanel } from '@twago/flows'
import { EFlowType, EResponsiveMenuEntryType, ETalentHistoryEntryType } from '@/helpers/enums'

export function talentMenuList(obj) {
  const disabled = obj?.disabled || []
  const display = obj.ctx.$store.state.search.talentMenu || []
  const idSuffix = obj.ctx.source?.id ?? 'bulk'

  return [
    {
      id: `inviteToProject_${idSuffix}`,
      event: EFlowType.INVITE_TO_PROJECT,
      icon: '$vuetify.icons.inviteProject',
      label: obj.ctx.$t('msp.v2.link.invite.to.project'),
      disabled: disabled.includes(EFlowType.INVITE_TO_PROJECT),
      display: display.includes(EFlowType.INVITE_TO_PROJECT)
    },
    {
      id: `applyToProject_${idSuffix}`,
      event: EFlowType.APPLY_TO_PROJECT,
      icon: '$vuetify.icons.applyProject',
      label: obj.ctx.$t('msp.v2.link.apply.to.project'),
      disabled: disabled.includes(EFlowType.APPLY_TO_PROJECT),
      display:
        obj.ctx.$useConfig(obj.ctx.$EClientConfig.MSP_APPLIES_TALENT) &&
        display.includes(EFlowType.APPLY_TO_PROJECT)
    },
    {
      id: `addToPool_${idSuffix}`,
      event: EFlowType.ADD_TO_POOL,
      icon: '$vuetify.icons.addPool',
      label: obj.ctx.$t('msp.v2.link.add.to.pool'),
      disabled: disabled.includes(EFlowType.ADD_TO_POOL),
      display: display.includes(EFlowType.ADD_TO_POOL)
    },
    {
      id: `emailTalent_${idSuffix}`,
      event: EFlowType.SEND_EMAIL,
      icon: '$vuetify.icons.email',
      label: obj.ctx.$t('msp.v2.link.send.email'),
      disabled: disabled.includes(EFlowType.SEND_EMAIL),
      display: obj.ctx.talentData?.email ? display.includes(EFlowType.SEND_EMAIL) : null
    }
  ]
}

export function talentMenuListBucketActions(obj) {
  const disabled = obj?.disabled || []
  const display = obj.ctx.$store.state.search.talentMenu || []
  const idSuffix = obj.ctx.source?.id ?? 'bulk'

  return [
    {
      id: `inviteToProject_${idSuffix}`,
      event: EFlowType.INVITE_TO_PROJECT,
      icon: '$vuetify.icons.inviteProject',
      label: obj.ctx.$t('msp.v2.link.invite.to.project'),
      disabled: disabled.includes(EFlowType.INVITE_TO_PROJECT),
      display: display.includes(EFlowType.INVITE_TO_PROJECT)
    },
    {
      id: `applyToProject_${idSuffix}`,
      event: EFlowType.APPLY_TO_PROJECT,
      icon: '$vuetify.icons.applyProject',
      label: obj.ctx.$t('msp.v2.link.apply.to.project'),
      disabled: disabled.includes(EFlowType.APPLY_TO_PROJECT),
      display:
        obj.ctx.$useConfig(obj.ctx.$EClientConfig.MSP_APPLIES_TALENT) &&
        display.includes(EFlowType.APPLY_TO_PROJECT)
    },
    {
      id: `addToPool_${idSuffix}`,
      event: EFlowType.ADD_TO_POOL,
      icon: '$vuetify.icons.addPool',
      label: obj.ctx.$t('msp.v2.link.add.to.pool'),
      disabled: disabled.includes(EFlowType.ADD_TO_POOL),
      display: display.includes(EFlowType.ADD_TO_POOL)
    },
    {
      id: `emailTalent_${idSuffix}`,
      event: EFlowType.SEND_EMAIL,
      icon: '$vuetify.icons.email',
      label: obj.ctx.$t('msp.v2.link.send.email'),
      disabled: disabled.includes(EFlowType.SEND_EMAIL),
      display: display.includes(EFlowType.SEND_EMAIL)
    }
  ]
}

export function talentPreviewMenuList(ctx) {
  const isPanelVisible = ctx.$store.getters['talent/isPanelVisible']
  const getTitle = (panelName) =>
    ctx.$t(ctx.$store.getters['talent/getPanelConfig'](panelName)?.title)

  return [
    {
      id: 'overview',
      title: ctx.$t('msp.v2.talent.menu.overview'),
      children: [
        {
          id: 'attachments',
          to: '#attachments',
          title: getTitle(ETalentPanel.ATTACHMENTS),
          show: isPanelVisible(ETalentPanel.ATTACHMENTS) || isPanelVisible(ETalentPanel.RESUME)
        },
        {
          id: 'recruiterTools',
          to: '#recruiterTools',
          title: ctx.$t('msp.v2.talent.menu.recruitertools')
        },
        {
          id: 'lastApplications',
          to: '#lastApplications',
          title: ctx.$t('msp.v2.talent.menu.lastapplications')
        }
      ]
    },
    {
      id: 'resume',
      title: ctx.$t('msp.v2.talent.menu.resume'),
      children: [
        {
          id: 'summary',
          to: '#summary',
          title: getTitle(ETalentPanel.SUMMARY),
          show: isPanelVisible(ETalentPanel.SUMMARY)
        },
        {
          id: 'experience',
          to: '#experience',
          title: getTitle(ETalentPanel.WORK_EXPERIENCE),
          show: isPanelVisible(ETalentPanel.WORK_EXPERIENCE)
        },
        {
          id: 'education',
          to: '#education',
          title: getTitle(ETalentPanel.EDUCATION),
          show: isPanelVisible(ETalentPanel.EDUCATION)
        },
        {
          id: 'certificates',
          to: '#certificates',
          title: getTitle(ETalentPanel.CERTIFICATES),
          show: isPanelVisible(ETalentPanel.CERTIFICATES)
        },
        {
          id: 'skills',
          to: '#skills',
          title: getTitle(ETalentPanel.SKILLS),
          show: isPanelVisible(ETalentPanel.SKILLS)
        },
        {
          id: 'industries',
          to: '#industries',
          title: getTitle(ETalentPanel.INDUSTRIES),
          show: isPanelVisible(ETalentPanel.INDUSTRIES)
        },
        {
          id: 'technologies',
          to: '#technologies',
          title: getTitle(ETalentPanel.TECHNOLOGIES),
          show: isPanelVisible(ETalentPanel.TECHNOLOGIES)
        },
        {
          id: 'languages',
          to: '#languages',
          title: getTitle(ETalentPanel.LANGUAGES),
          show: isPanelVisible(ETalentPanel.LANGUAGES)
        }
      ]
    }
  ]
}

export const talentDottedMenu = (ctx) => [
  {
    id: 'openInNewTabBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.openinnewtab'),
    standoutBreakpoint: 'lgAndUp',
    icon: '$vuetify.icons.openNewTab',
    attrs: {
      nuxt: true,
      href: ctx.$nuxt.$route.path,
      target: 'blank'
    }
  },
  {
    id: 'inviteToProjectEntryBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.invitetoproject'),
    standoutBreakpoint: 'lgAndUp',
    icon: '$vuetify.icons.work',
    on: {
      click: () => ctx.openFlowModal(EFlowType.INVITE_TO_PROJECT)
    }
  },
  {
    id: 'sendEmailBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.sendemail'),
    standoutBreakpoint: 'mdAndUp',
    icon: '$vuetify.icons.email',
    condition: Boolean(ctx.talentData?.emailVerified),
    on: {
      click: () => ctx.openFlowModal(EFlowType.SEND_EMAIL)
    }
  },
  {
    id: 'emailNotVerifiedBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.email.not.verified'),
    standoutBreakpoint: 'mdAndUp',
    icon: '$vuetify.icons.emailNotVerified',
    condition: Boolean(!ctx.talentData?.emailVerified && ctx.talentData?.email)
  },
  {
    id: 'applyToProjectBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.applytoproject'),
    icon: '$vuetify.icons.work',
    condition: Boolean(
      ctx.$store.state.clientContext.hasConsentEnabled && ctx.talentData?.consentTo?.length
    ),
    on: {
      click: () => ctx.openFlowModal(EFlowType.APPLY_TO_PROJECT)
    }
  },
  {
    id: 'changeTierSeparator',
    type: EResponsiveMenuEntryType.SEPARATOR,
    condition: Boolean(ctx.$store.getters.tiers?.length)
  },
  {
    id: 'changeTierSubmenu',
    type: EResponsiveMenuEntryType.SUBMENU,
    label: ctx.$t('msp.v2.talent.menu.changetier'),
    condition: Boolean(ctx.$store.getters.tiers?.length),
    children: [
      {
        id: 'tierNoneBtn',
        label: ctx.$t('msp.v2.talent.menu.tiernone'),
        attrs: {
          disabled: ctx.isCurrentTier('')
        },
        on: {
          click: () => {
            ctx.newTier = null
            ctx.showChangeTierModal = !ctx.showChangeTierModal
          }
        }
      },
      ...ctx.$store.getters.tiers.map((tier, idx) => ({
        id: `clickTierAction_${idx}`,
        label: ctx.$t('msp.v2.talent.menu.tier', { n: tier.level }),
        attrs: {
          disabled: ctx.isCurrentTier(tier.level)
        },
        on: {
          click: () => {
            ctx.newTier = tier.level
            ctx.showChangeTierModal = !ctx.showChangeTierModal
          }
        }
      }))
    ]
  },
  {
    id: 'deleteDataSeparator',
    type: EResponsiveMenuEntryType.SEPARATOR
  },
  {
    id: 'deactivateTalentBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.deactivate'),
    condition: ctx.isActive,
    on: {
      click: () => {
        ctx.showTalentDeactivationModal = !ctx.showTalentDeactivationModal
      }
    }
  },
  {
    id: 'reactivateTalentBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.reactivate'),
    condition: !ctx.isActive,
    on: {
      click: () => {
        ctx.showTalentReactivationModal = !ctx.showTalentReactivationModal
      }
    }
  },
  {
    id: 'deleteTalentBtn',
    type: EResponsiveMenuEntryType.ENTRY,
    label: ctx.$t('msp.v2.talent.menu.delete'),
    attrs: {
      color: 'red'
    },
    on: {
      click: () => {
        ctx.showTalentDeletionModal = !ctx.showTalentDeletionModal
      }
    }
  }
]

export const talentHistoryFilters = (ctx) => [
  {
    id: 'logs',
    value: ETalentHistoryEntryType.LOG,
    label: ctx.$t('msp.v2.talent.history.filters.logs')
  },

  {
    id: 'pools',
    value: ETalentHistoryEntryType.POOL,
    label: ctx.$t('msp.v2.talent.history.filters.pools')
  },

  {
    id: 'projects',
    value: ETalentHistoryEntryType.PROJECT,
    label: ctx.$t('msp.v2.talent.history.filters.projects')
  }
]
