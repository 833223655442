
import { mapGetters } from 'vuex'
import { ToastPanel } from '@twago/toast'

export default {
  components: { ToastPanel },

  head() {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/favicon.png'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      pageLoader: 'pageLoader'
    })
  },
  beforeDestroy() {
    this.$store.dispatch('polling/clearInterval')
  }
}
