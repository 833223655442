
import { mapGetters } from 'vuex'
import { ECalendarStatus } from '@/helpers/enums'
import { connectCalendar } from '@/helpers/index'
import { toolbarMenu } from '@/helpers/menus'

export default {
  name: 'BaseUserMenu',

  computed: {
    ...mapGetters({
      calendarEnabled: 'calendarEnabled',
      mobileMenu: 'mobileMenu'
    }),

    calendarStatus() {
      return this.$auth.user.calendar.connectionStatus
    },

    canConnect() {
      return [ECalendarStatus.CF, ECalendarStatus.NC, ECalendarStatus.AR].includes(
        this.calendarStatus
      )
    },

    toolbarMenu() {
      return toolbarMenu(this)
    },

    toggleMenu: {
      get() {
        return this.mobileMenu
      },
      set(val) {
        this.$store.commit('TOGGLE_MOBILE_MENU', val)
      }
    }
  },

  watch: {
    $route() {
      this.toggleMenu = false
    }
  },

  methods: {
    connectCalendar() {
      connectCalendar(this.$store.state.clientContext.googleCalendarAppClientId)
    },

    clickCalendar() {
      if (this.canConnect) {
        this.connectCalendar()
      } else {
        window.open('https://calendar.google.com/calendar')
      }
    }
  }
}
