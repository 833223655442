
import { mapGetters } from 'vuex'
import { ETalentPanel, ETalentAttachmentType } from '@twago/flows'

export default {
  name: 'AppTabAttachments',

  data: () => ({
    attachmentsNames: {},
    loading: true
  }),

  computed: {
    ...mapGetters({
      getPanelConfig: 'talent/getPanelConfig',
      isPanelVisible: 'talent/isPanelVisible'
    }),

    attachments() {
      const extractAttachments = (cvEntriesArray) =>
        cvEntriesArray.map((uuid) => ({
          uuid,
          name: this.attachmentsNames[uuid],
          link: `/napi/resource/${uuid}`
        }))

      return {
        workExperience: {
          entries: extractAttachments(this.jobAttachmentsUuids),
          title: this.$t('msp.v2.application.attachments.category.work.experience')
        },
        education: {
          entries: extractAttachments(this.educationAttachmentsUuids),
          title: this.$t('msp.v2.application.attachments.category.education')
        },
        certificates: {
          entries: extractAttachments(this.certificateAttachmentsUuids),
          title: this.$t('msp.v2.application.attachments.category.certificates')
        }
      }
    },

    certificateAttachmentsUuids() {
      return this.getPanelAttachmentUuids(ETalentPanel.CERTIFICATES)
    },

    educationAttachmentsUuids() {
      return this.getPanelAttachmentUuids(ETalentPanel.EDUCATION)
    },

    jobAttachmentsUuids() {
      return this.getPanelAttachmentUuids(ETalentPanel.WORK_EXPERIENCE)
    }
  },

  async mounted() {
    await this.populateAttachmentNames()
    this.loading = false
  },

  methods: {
    async populateAttachmentNames() {
      const requests = [
        ...this.certificateAttachmentsUuids,
        ...this.educationAttachmentsUuids,
        ...this.jobAttachmentsUuids
      ].map(async (uuid) => {
        const { data } = await this.$api.resource.getMeta(uuid)
        return [uuid, data.name]
      })
      const namesArr = await Promise.all(requests)
      this.attachmentsNames = Object.fromEntries(namesArr)
    },

    getPanelAttachmentUuids(panelName) {
      const config = this.getPanelConfig(panelName)
      const isVisible = this.isPanelVisible(panelName)
      const hasValues = Array.isArray(config.value) && config.value.length
      const hasFormAttachments = config.form?.some(
        (field) => field.name === ETalentAttachmentType.HISTORY
      )

      if (!isVisible || !hasValues || !hasFormAttachments) {
        return []
      }

      // prettier-ignore
      return config.value
        .map((val) => val[ETalentAttachmentType.HISTORY])
        .filter(Boolean)
    }
  }
}
