import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c27d8da2 = () => interopDefault(import('../pages/analytics.vue' /* webpackChunkName: "pages/analytics" */))
const _75895d86 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _34633600 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _089922f6 = () => interopDefault(import('../pages/dashboard/pools.vue' /* webpackChunkName: "pages/dashboard/pools" */))
const _e4e78a88 = () => interopDefault(import('../pages/dashboard/projects.vue' /* webpackChunkName: "pages/dashboard/projects" */))
const _51322980 = () => interopDefault(import('../pages/pools.vue' /* webpackChunkName: "pages/pools" */))
const _c9c9fdec = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _b5e007e4 = () => interopDefault(import('../pages/social-callback.vue' /* webpackChunkName: "pages/social-callback" */))
const _44ff0b8a = () => interopDefault(import('../pages/ui.vue' /* webpackChunkName: "pages/ui" */))
const _93af66f6 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _1923cc2e = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9f1dafee = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _67eb909f = () => interopDefault(import('../pages/calendar/callback.vue' /* webpackChunkName: "pages/calendar/callback" */))
const _3ed046d9 = () => interopDefault(import('../pages/project/create.vue' /* webpackChunkName: "pages/project/create" */))
const _07dbc86a = () => interopDefault(import('../pages/search/projects.vue' /* webpackChunkName: "pages/search/projects" */))
const _2ea5cec7 = () => interopDefault(import('../pages/search/talents.vue' /* webpackChunkName: "pages/search/talents" */))
const _51b22912 = () => interopDefault(import('../pages/search/external/talents/_provider/index.vue' /* webpackChunkName: "pages/search/external/talents/_provider/index" */))
const _2f975f9f = () => interopDefault(import('../pages/search/external/talents/_provider/_id.vue' /* webpackChunkName: "pages/search/external/talents/_provider/_id" */))
const _57d3aa7e = () => interopDefault(import('../pages/manage/project/_id.vue' /* webpackChunkName: "pages/manage/project/_id" */))
const _76dea648 = () => interopDefault(import('../pages/manage/project/_id/edit.vue' /* webpackChunkName: "pages/manage/project/_id/edit" */))
const _ecbdc3b2 = () => interopDefault(import('../pages/manage/project/_id/unpublished.vue' /* webpackChunkName: "pages/manage/project/_id/unpublished" */))
const _2d3ef384 = () => interopDefault(import('../route/application.vue' /* webpackChunkName: "" */))
const _4d770c2e = () => interopDefault(import('../pages/pool/_id.vue' /* webpackChunkName: "pages/pool/_id" */))
const _6d053eeb = () => interopDefault(import('../pages/pool/_id/projects.vue' /* webpackChunkName: "pages/pool/_id/projects" */))
const _19230426 = () => interopDefault(import('../pages/pool/_id/talents.vue' /* webpackChunkName: "pages/pool/_id/talents" */))
const _62275a87 = () => interopDefault(import('../route/project.vue' /* webpackChunkName: "" */))
const _4e3dd9b2 = () => interopDefault(import('../route/dataHarbour.vue' /* webpackChunkName: "" */))
const _a1b2afde = () => interopDefault(import('../route/suggested.vue' /* webpackChunkName: "" */))
const _5312a894 = () => interopDefault(import('../route/summary.vue' /* webpackChunkName: "" */))
const _7a3854b8 = () => interopDefault(import('../route/pipeline.vue' /* webpackChunkName: "" */))
const _38989d3e = () => interopDefault(import('../pages/talent/_id.vue' /* webpackChunkName: "pages/talent/_id" */))
const _ec3c1e0a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics",
    component: _c27d8da2,
    pathToRegexpOptions: {"strict":true},
    name: "analytics"
  }, {
    path: "/dashboard",
    component: _75895d86,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _34633600,
      pathToRegexpOptions: {"strict":true},
      name: "dashboard"
    }, {
      path: "pools",
      component: _089922f6,
      pathToRegexpOptions: {"strict":true},
      name: "dashboard-pools"
    }, {
      path: "projects",
      component: _e4e78a88,
      pathToRegexpOptions: {"strict":true},
      name: "dashboard-projects"
    }]
  }, {
    path: "/pools",
    component: _51322980,
    pathToRegexpOptions: {"strict":true},
    name: "pools"
  }, {
    path: "/settings",
    component: _c9c9fdec,
    pathToRegexpOptions: {"strict":true},
    name: "settings"
  }, {
    path: "/social-callback",
    component: _b5e007e4,
    pathToRegexpOptions: {"strict":true},
    name: "social-callback"
  }, {
    path: "/ui",
    component: _44ff0b8a,
    pathToRegexpOptions: {"strict":true},
    name: "ui"
  }, {
    path: "/auth/forgot-password",
    component: _93af66f6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _1923cc2e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login"
  }, {
    path: "/auth/reset-password",
    component: _9f1dafee,
    pathToRegexpOptions: {"strict":true},
    name: "auth-reset-password"
  }, {
    path: "/calendar/callback",
    component: _67eb909f,
    pathToRegexpOptions: {"strict":true},
    name: "calendar-callback"
  }, {
    path: "/project/create",
    component: _3ed046d9,
    pathToRegexpOptions: {"strict":true},
    name: "project-create"
  }, {
    path: "/search/projects",
    component: _07dbc86a,
    pathToRegexpOptions: {"strict":true},
    name: "search-projects"
  }, {
    path: "/search/talents",
    component: _2ea5cec7,
    pathToRegexpOptions: {"strict":true},
    name: "search-talents"
  }, {
    path: "/search/external/talents/:provider",
    component: _51b22912,
    pathToRegexpOptions: {"strict":true},
    name: "search-external-talents-provider"
  }, {
    path: "/search/external/talents/:provider?/:id",
    component: _2f975f9f,
    pathToRegexpOptions: {"strict":true},
    name: "search-external-talents-provider-id"
  }, {
    path: "/manage/project/:id?",
    component: _57d3aa7e,
    pathToRegexpOptions: {"strict":true},
    name: "manage-project-id",
    children: [{
      path: "edit",
      component: _76dea648,
      pathToRegexpOptions: {"strict":true},
      name: "manage-project-id-edit"
    }, {
      path: "unpublished",
      component: _ecbdc3b2,
      pathToRegexpOptions: {"strict":true},
      name: "manage-project-id-unpublished"
    }]
  }, {
    path: "/application/:appId",
    component: _2d3ef384,
    name: "application-appId"
  }, {
    path: "/pool/:id?",
    component: _4d770c2e,
    pathToRegexpOptions: {"strict":true},
    name: "pool-id",
    children: [{
      path: "projects",
      component: _6d053eeb,
      pathToRegexpOptions: {"strict":true},
      name: "pool-id-projects"
    }, {
      path: "talents",
      component: _19230426,
      pathToRegexpOptions: {"strict":true},
      name: "pool-id-talents"
    }]
  }, {
    path: "/project/:id?",
    component: _62275a87,
    name: "project-id",
    children: [{
      path: "dataHarbour",
      component: _4e3dd9b2,
      name: "project-id-dataHarbour"
    }, {
      path: "suggested",
      component: _a1b2afde,
      name: "project-id-suggested"
    }, {
      path: "summary",
      component: _5312a894,
      name: "project-id-summary"
    }, {
      path: ":status?",
      component: _7a3854b8,
      name: "project-id-status"
    }]
  }, {
    path: "/talent/:id?",
    component: _38989d3e,
    pathToRegexpOptions: {"strict":true},
    name: "talent-id"
  }, {
    path: "/project/:id?/:status?/labels",
    component: _62275a87,
    name: "project-id-status-labels",
    children: [{
      path: ":labelId?",
      component: _7a3854b8,
      name: "project-id-status-labels-labelId"
    }]
  }, {
    path: "/project/:id?/:status?/application/:appId",
    component: _2d3ef384,
    name: "project-id-status-application-appId"
  }, {
    path: "/project/:id?/:status?/labels/:labelId?/application/:appId",
    component: _2d3ef384,
    name: "project-id-status-labels-labelId-application-appId"
  }, {
    path: "/",
    component: _ec3c1e0a,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
