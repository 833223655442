export function extractMspGtmData({ auth, store }) {
  const { id, firstName, lastName, email } = auth.user
  const { clientId } = store.state.clientContext

  return {
    userType: 'msp',
    userId: `msp_${id}_${clientId}`,
    userInternalId: id,
    firstName,
    lastName,
    email
  }
}
