export default (axios) => ({
  create(binary) {
    const binaryData = new FormData()
    binaryData.append('resource', binary)

    return axios.post('/api/resource', binaryData)
  },

  get(uuid) {
    return axios.get(`/api/resource/${uuid}`)
  },

  getMeta(uuid) {
    return axios.get(`/napi/resource/${uuid}/meta`)
  }
})
