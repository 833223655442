import { EGtmEvent } from '@/helpers/enums'
import { extractMspGtmData } from '@/helpers/tracking'

const NUXT_GTM_TIMEOUT = 250

export default function ({ app, store }) {
  app.router.afterEach(() => {
    const auth = app.$auth

    if (auth.loggedIn) {
      setTimeout(
        () => app.$pushEvent(EGtmEvent.MSP_LOGGED_DATA, extractMspGtmData({ auth, store }), true),
        NUXT_GTM_TIMEOUT
      )
    }
  })
}
