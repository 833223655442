export default (axios) => ({
  create(body) {
    return axios.post('/api/locationTemplate', body)
  },
  getAllByLe(id) {
    return axios.get(`/api/locationTemplate?legalEntityId=${id}`)
  },
  deleteTemplateById(id) {
    return axios.delete(`/api/locationTemplate/${id}`)
  },
  getTemplateById(id) {
    return axios.get(`/api/locationTemplate/${id}`)
  },
  update(id, body) {
    return axios.put(`/api/locationTemplate/${id}`, body)
  }
})
