import { render, staticRenderFns } from "./inner.vue?vue&type=template&id=1cd7190a&"
import script from "./inner.vue?vue&type=script&lang=js&"
export * from "./inner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSystemTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Layout/SystemTemplate.vue').default,BasePollingTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/PollingTemplate.vue').default,LayoutAnnouncementTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Layout/AnnouncementTemplate.vue').default})
