import LRU from 'lru-cache'
import getIcons from '@/config/icons'

const themeCache = new LRU({
  max: 10,
  ttl: 1000 * 60 * 60
})

export default {
  font: false,
  icons: {
    values: { ...getIcons }
  },
  theme: {
    dark: false,
    options: {
      cspNonce: 'dQw4w9WgXcQ',
      customProperties: true,
      themeCache,
      minifyTheme(css) {
        return process.env.NODE_ENV === 'production' ? css.replace(/[\r\n|\r|\n]/g, '') : css
      }
    }
  }
}
