
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'FormFieldPickerChip',

  components: { ValidationProvider },

  props: {
    value: {
      type: String,
      default: null
    },

    label: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    },

    items: {
      type: Array,
      default: () => []
    },

    itemText: {
      type: String,
      default: 'text'
    },

    itemValue: {
      type: String,
      default: 'value'
    },

    rules: {
      type: String,
      default: null
    },

    id: {
      type: String,
      required: true
    }
  },

  computed: {
    selectedChip: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
