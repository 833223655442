export const state = () => ({
  pollingResults: [],
  pollingInterval: null,
  pollingIntervalIds: []
})

export const actions = {
  async fetchPollingData({ commit }) {
    try {
      const response = await this.$api.polling.getTasks()

      const computedResponse = response.data.results.map((pollingResult) => {
        return {
          ...pollingResult,
          snackbarOpen: true
        }
      })
      commit('SET_POLLING_DATA', computedResponse)

      const onlyFailedStatuses = computedResponse.every((result) => result.status === 'FAILED')

      if (!computedResponse?.length || onlyFailedStatuses) {
        return clearInterval(this.pollingInterval)
      }
    } catch (error) {}
  },
  startPolling({ dispatch, rootState, state, commit }) {
    const context = this.$useConfig(this.$EClientConfig.TASKS_SETTINGS)
    const onAction = context.pollingIntervalSeconds.onAction

    if (onAction) {
      const interval = onAction * 1000

      const intervalId = setInterval(() => {
        dispatch('fetchPollingData')
      }, interval)
      commit('ADD_INTERVAL_ID', intervalId)
    }
  },

  clearInterval({ commit, state }) {
    state.pollingIntervalIds.forEach((intervalId) => {
      clearInterval(intervalId)
    })
    commit('CLEAR_INTERVAL')
  },
  startPollingIdle({ dispatch, rootState, commit }) {
    const context = this.$useConfig(this.$EClientConfig.TASKS_SETTINGS)
    const onIdle = context.pollingIntervalSeconds.onIdle

    if (onIdle) {
      const interval = onIdle * 1000

      const intervalId = setInterval(() => {
        dispatch('fetchPollingData')
      }, interval)
      commit('ADD_INTERVAL_ID', intervalId)
    }
  }
}

export const mutations = {
  SET_POLLING_DATA: (state, payload) => {
    state.pollingResults = payload
  },
  CLOSE_SNACKBAR: (state, payload) => {
    state.pollingResults = state.pollingResults.map((pollingResult) => {
      if (pollingResult.uuid === payload) {
        pollingResult.snackbarOpen = false
      }
      return pollingResult
    })
  },
  CLEAR_INTERVAL: (state) => {
    state.pollingIntervalIds = []
  },
  ADD_INTERVAL_ID: (state, payload) => {
    state.pollingIntervalIds.push(payload)
  }
}

export const getters = {
  pollingResults: (state) => {
    return state.pollingResults
  }
}
