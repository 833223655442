import Dashboard from '@/services/dashboard'
import Account from '@/services/account'
import Project from '@/services/project'
import Application from '@/services/application'
import Polling from '@/services/polling'
import Question from '@/services/question'
import Msp from '@/services/msp'
import Client from '@/services/client'
import Freelancer from '@/services/freelancer'
import Pool from '@/services/pool'
import Membership from '@/services/membership'
import Resource from '@/services/resource'
import Es from '@/services/es'
import Tag from '@/services/tag'
import Utils from '@/services/utils'
import Matching from '@/services/matching'
import ActionLog from '@/services/actionLog'
import PreApplicationQuestion from '@/services/preApplication'
import Vms from '@/services/vms'
import Cms from '@/services/cms'
import LocationTemplate from '@/services/locationTemplate'

export default ({ $axios, $cmsApiCall, $fallbackLangs, store }, inject) => {
  const factories = {
    dashboard: Dashboard($axios),
    account: Account($axios),
    project: Project($axios),
    application: Application($axios),
    polling: Polling($axios),
    question: Question($axios),
    msp: Msp($axios),
    client: Client($axios),
    freelancer: Freelancer($axios),
    pool: Pool($axios),
    membership: Membership($axios),
    resource: Resource($axios),
    es: Es($axios),
    tag: Tag($axios),
    utils: Utils($axios),
    matching: Matching($axios),
    actionLog: ActionLog($axios),
    preapp: PreApplicationQuestion($axios),
    vms: Vms($axios),
    cms: Cms({ $cmsApiCall, $fallbackLangs, store }),
    locationTemplate: LocationTemplate($axios)
  }

  inject('api', factories)
}
