export default (axios) => ({
  createBulk(body) {
    return axios.post('/api/tags', body)
  },

  update(tagId, tag) {
    return axios.post(`/api/tag/${tagId}/rename?tagName=${encodeURIComponent(tag)}`)
  },

  merge(body) {
    return axios.post('/api/tag/merge', body)
  },

  assign(freelancerId, body) {
    return axios.post(`/api/freelancer/${freelancerId}/assignTag`, body)
  },

  createAndAssign(freelancerId, body) {
    return axios.post(`/api/freelancer/${freelancerId}/createAndAssignTag`, body)
  },

  unassign(freelancerId, body) {
    return axios.post(`/api/freelancer/${freelancerId}/unAssignTag`, body)
  },

  get(freelancerId) {
    return axios.get(`/api/freelancer/${freelancerId}/tags`)
  },

  getBulk(query) {
    return axios.get(`/api/tag/listAll`, {
      params: {
        from: 0,
        size: 999,
        ...query
      }
    })
  },

  deleteBulk(body) {
    return axios.delete('/api/tag/delete', { data: body })
  }
})
