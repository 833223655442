export default (axios) => ({
  getPool(poolId, query) {
    return axios.get(`/api/pool/membership/pool/${poolId}`, {
      params: {
        ...query
      }
    })
  },

  getBulkPools(query) {
    return axios.get(`/api/pool/membership/pools`, {
      params: {
        ...query
      }
    })
  }
})
