
import { EActionLogType, EManualLogType } from '@/helpers/enums'

export default {
  name: 'BaseNotePreview',

  props: {
    content: {
      type: String,
      required: true
    },

    createdAt: {
      type: [Date, String],
      required: true
    },

    createdBy: {
      type: String,
      required: true
    },

    executionDate: {
      type: [Date, String],
      default: null
    },

    id: {
      type: String,
      required: true
    },

    type: {
      type: String,
      required: true
    }
  },

  computed: {
    firstName() {
      return this.createdBy.split(' ')[0]
    },

    lastName() {
      const arr = this.createdBy.split(' ')

      return arr[arr.length - 1]
    },
    readableType() {
      switch (this.type) {
        case EActionLogType.CALL:
        case EManualLogType.CALL:
          return this.$t('msp.v2.notes.types.call')
        case EActionLogType.EMAIL:
          return this.$t('msp.v2.notes.types.email')
        case EManualLogType.INTERVIEW:
          return this.$t('msp.v2.notes.types.interview')
        case EActionLogType.MEETING:
          return this.$t('msp.v2.notes.types.meeting')
        case EActionLogType.NOTE:
        case EManualLogType.NOTE:
          return this.$t('msp.v2.notes.types.note')
        default:
          throw new Error('Invalid action log type')
      }
    }
  }
}
