
export default {
  name: 'LayoutSystemTemplate',

  data() {
    return {
      networkStatus: false
    }
  },

  computed: {
    networkStatusColor() {
      return this.$nuxt.isOnline ? 'green' : 'red'
    }
  },

  watch: {
    '$nuxt.isOnline'(bool) {
      if (bool) {
        setTimeout(() => (this.networkStatus = false), 1000)
      } else {
        this.networkStatus = true
      }
    }
  }
}
