
import Avatar from 'vue-avatar'

export default {
  name: 'BaseAvatarTemplate',

  components: { Avatar },

  props: {
    size: {
      type: Number,
      default: 48
    },
    username: {
      type: String,
      default: undefined
    }
  },

  computed: {
    normalizedUsername() {
      if (this.username) {
        const normalize = this.username.replace(/-/g, '').split(' ', 2)
        return normalize.join(' ')
      } else {
        return undefined
      }
    }
  }
}
