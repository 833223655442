import colors from 'vuetify/lib/util/colors'

const commonColours = {
  ...colors,
  primary: '#212121',
  grey: {
    ...colors.grey,
    base: '#212121',
    darken1: '#757575',
    darken2: '#616161',
    darken3: '#424242',
    lighten1: '#BDBDBD',
    lighten2: '#E0E0E0',
    lighten3: '#EEEEEE',
    lighten4: '#F5F5F5',
    lighten5: '#FAFAFA'
  },
  green: {
    ...colors.green,
    darken4: '#1B5E20',
    accent4: '#00C853'
  },
  success: '#00C853',
  error: '#B71C1C',
  info: '#01579B',
  warning: '#E65100'
}

export const clientTheme = ({ clientContext }) => {
  return {
    locales: clientContext.languages.map(({ iso }) => iso),
    light: { ...commonColours }
  }
}
