
export default {
  name: 'BaseModalTemplate',

  props: {
    isLoading: Boolean,
    modalOpen: Boolean,
    hideDefaultClose: Boolean,
    hideDefaultCancelButton: Boolean,
    modalTitle: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '640px'
    }
  },

  computed: {
    dialog: {
      get() {
        return this.modalOpen
      },
      set(value) {
        this.$emit('update:modalOpen', value)
      }
    }
  },

  methods: {
    resetValidation() {
      return this.$refs.form?.resetValidation()
    },

    validate() {
      return this.$refs.form?.validate()
    }
  }
}
