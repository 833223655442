
import { ToastPanel } from '@twago/toast'

export default {
  name: 'Empty',

  components: { ToastPanel },

  head() {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: '/favicon.png'
        }
      ]
    }
  },
  beforeDestroy() {
    this.$store.dispatch('polling/clearInterval')
  }
}
