
import { mapGetters } from 'vuex'

export default {
  name: 'BaseMobileMenu',

  computed: {
    ...mapGetters({
      mobileMenu: 'mobileMenu'
    }),

    toggleMenu: {
      get() {
        return this.mobileMenu
      },
      set(val) {
        this.$store.commit('TOGGLE_MOBILE_MENU', val)
      }
    }
  }
}
