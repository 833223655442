export default (axios) => ({
  getToJob(jobId) {
    return axios.get(`/api/match/freelancers/toJob/${jobId}`)
  },

  getToFreelancer(freelancerId) {
    return axios.get(`/api/match/pools/toFreelancer/${freelancerId}`)
  },

  createToText(body) {
    return axios.get('/api/match/freelancers/toText', body)
  }
})
