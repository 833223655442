export default (axios) => ({
  talentQuery(body) {
    return axios.post('/api/search/freelancer/query', body)
  },

  projectQuery(body) {
    return axios.post('/api/search/project/query', body)
  },

  applicationQuery(body) {
    return axios.post('/api/search/projectApplication/query', body)
  },

  applicationQueryDb(body) {
    return axios.post('/api/search/projectApplication/queryDb', body)
  },

  invitationQuery(projectId, body) {
    return axios.post(`/api/search/projectInvitation/${projectId}/query`, body)
  },

  poolQuery(body) {
    return axios.post('/api/search/pool/query', body)
  },

  externalMonster(body) {
    return axios.post('/api/search/freelancer/external/resume', body)
  },

  externalGetProfile(resumeId) {
    return axios.get(`/api/search/freelancer/external/${resumeId}/profileFetch`)
  }
})
