import { setupPermissions } from '@/helpers/auth'

export default function ({ app, $auth, $axios, store }, inject) {
  let refreshTimer = null

  $auth.options.redirect = {
    login: '/auth/login',
    logout: '/auth/login',
    home: '/',
    callback: '/'
  }

  async function triggerTokenChecker() {
    const expires = $auth.$storage.getUniversal('_token_expiration.local')
    await $auth.initRefreshInterval(expires)
  }

  function setupCalendarEnabled(loggedIn) {
    const enabled = loggedIn ? $auth.user.calendar.enabled : false
    store.commit('CALENDAR_ENABLED', enabled)
  }

  $axios.onRequest(() => {
    triggerTokenChecker()
  })

  $auth.initRefreshInterval = async (accessTokenExp) => {
    const currentDateTime = Date.parse(new Date())
    const refreshPeriod = accessTokenExp - currentDateTime - 60000 * 5

    if (process.client) {
      clearTimeout(refreshTimer)

      refreshTimer = setTimeout(async () => {
        await $auth.refreshTokens()
        triggerTokenChecker()
      }, refreshPeriod)
    } else if (refreshPeriod < 0) {
      await $auth.refreshTokens()
    }
  }

  setupCalendarEnabled($auth.loggedIn)
  setupPermissions($auth)

  if (process.client) {
    $auth.$storage.watchState('loggedIn', (loggedIn) => {
      setupCalendarEnabled(loggedIn)

      if (!loggedIn) {
        clearTimeout(refreshTimer)
      }

      if (loggedIn) {
        triggerTokenChecker()
        setupPermissions($auth)
      }
    })
  }

  inject('checkPermission', (permission) => $auth.user?.permissions?.includes(permission))
}
