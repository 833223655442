import { set, del } from 'vue'
import { EApplicationHistoryOperation } from '@/helpers/enums'

export const state = () => ({
  application: null,
  applicationsQueue: [],
  assessmentsEnabled: false,
  assessments: [],
  history: [],
  totalHistoryEntries: 0,
  questions: []
})

export const actions = {
  async fetchApplication({ commit }, applicationId) {
    const { data } = await this.$api.application.get(applicationId)
    commit('SET_APPLICATION', data)
  },

  async fetchAssessments({ commit, rootState }, { applicationId, legalEntityId }) {
    const legalEntities = rootState.clientContext.localLegalEntities
    const legalEntity = legalEntities.find((le) => le.id === legalEntityId)

    if (!legalEntity?.name) {
      throw new Error(`Could not find legal entity with id ${legalEntityId}`)
    }

    const assessmentsEnabled = this.$useLeConfig(
      legalEntity.name,
      this.$ELeConfig.ASSESSMENTS_ENABLED
    )
    commit('SET_ASSESSMENTS_ENABLED', assessmentsEnabled)

    if (assessmentsEnabled) {
      const { data } = await this.$api.application.getAssessmentTests(applicationId)
      commit('SET_ASSESSMENTS', data.results)
    } else {
      commit('SET_ASSESSMENTS', [])
    }
  },

  async fetchHistory({ commit }, applicationId) {
    try {
      const { data } = await this.$api.application.getHistory(applicationId, {
        offset: 0,
        size: 9999
      })
      commit('PUSH_HISTORY_ENTRIES', data.results)
      commit('SET_TOTAL_HISTORY_ENTRIES', data.totalResults)
    } catch (e) {}
  },

  async fetchQuestions({ commit, state }, applicationId) {
    try {
      const { data } = await this.$api.question.get(applicationId)

      const currentLength = state.questions.length
      const incomingLength = data.length
      const largest = currentLength > incomingLength ? currentLength : incomingLength

      for (let index = largest - 1; index >= 0; index--) {
        const oldQuestion = state.questions[index]
        const newQuestion = data[index]

        if (newQuestion && !oldQuestion) {
          commit('SET_QUESTION', { index, question: newQuestion })
        } else if (!newQuestion && oldQuestion) {
          commit('DEL_QUESTION', index)
        } else if (JSON.stringify(newQuestion) !== JSON.stringify(oldQuestion)) {
          commit('SET_QUESTION', { index, question: newQuestion })
        }
      }
    } catch (e) {}
  },

  deleteNote({ commit, state }, noteId) {
    const index = state.history.findIndex((h) => h.id === noteId)

    if (~index) {
      commit('DELETE_HISTORY_ENTRY', index)
    }
  }
}

export const mutations = {
  SET_APPLICATION: (state, application) => {
    state.application = application
  },

  SET_ASSESSMENTS_ENABLED: (state, enabled) => {
    state.assessmentsEnabled = enabled
  },

  SET_ASSESSMENTS: (state, assessments) => {
    state.assessments = assessments
  },

  PUSH_HISTORY_ENTRIES: (state, history) => {
    state.history = history
  },

  SET_TOTAL_HISTORY_ENTRIES: (state, count) => {
    state.totalHistoryEntries = count
  },

  SET_QUESTION: (state, { question, index }) => {
    set(state.questions, index, question)
  },

  DEL_QUESTION: (state, index) => {
    del(state.questions, index)
  },

  DELETE_HISTORY_ENTRY: (state, index) => {
    del(state.history, index)
  },

  SET_APPLICATIONS_QUEUE: (state, appQueue) => {
    state.applicationsQueue = appQueue
  }
}

export const getters = {
  application: ({ application }) => application,

  assessmentsEnabled: ({ assessmentsEnabled }) => assessmentsEnabled,

  assessments: ({ assessments }) => assessments,

  history: ({ history }) => history,

  talentId: ({ application }) => application.freelancer.id,

  questions: ({ questions }) => questions.filter((q) => q.mspVisibility),

  notes: ({ history }) =>
    history.filter((h) =>
      [
        EApplicationHistoryOperation.MSP_LOG_CALL,
        EApplicationHistoryOperation.MSP_LOG_INTERVIEW,
        EApplicationHistoryOperation.MSP_LOG_NOTE
      ].includes(h.operation)
    ),

  hasInterview: ({ application }) => typeof application.vmsInterview.id === 'number',

  hasOffer: ({ application }) => typeof application.vmsOffer.id === 'number',

  applicationsQueue: ({ applicationsQueue }) => applicationsQueue
}
