export default (axios) => ({
  callAuth0(body) {
    return axios.post('/api/authorizeWithAuth0Token', body)
  },

  callAuth0External(body) {
    return axios.post('/api/authorizeWithAuth0ExternalToken', body)
  },

  updateLanguage(body) {
    return axios.post('/api/msp/locale', body)
  },

  getBrokerCompany(query) {
    return axios.get('/api/brokercompany', {
      params: {
        ...query
      }
    })
  },

  getBrokerEmployee(query) {
    return axios.get('/api/brokeremployee', {
      params: {
        ...query
      }
    })
  },

  getAnnouncement() {
    return axios.get('/api/announce/now', { blockInterceptions: true })
  },

  getLanguages() {
    return axios.get('/api/languages')
  },

  getGeo(body) {
    return axios.post('/api/geo/autocomplete', body)
  },

  getLocations() {
    return axios.get('/api/project/siteLocations')
  },
  getCountry() {
    return axios.get('/api/country')
  }
})
