import { render, staticRenderFns } from "./NoteEdit.vue?vue&type=template&id=64a7cdc0&"
import script from "./NoteEdit.vue?vue&type=script&lang=js&"
export * from "./NoteEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormFieldInput: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Form/FieldInput.vue').default,FormFieldPickerChip: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Form/FieldPickerChip.vue').default,FormFieldPickerDate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Form/FieldPickerDate.vue').default})
