export default (axios) => ({
  authorizeWithUrlToken(token) {
    return axios.post('/api/authorizeWithUrlToken', { urlToken: token })
  },

  resetPassword(body) {
    return axios.put('/api/msp/resetPassword', body)
  },

  requestPasswordReset(body) {
    return axios.post('/api/msp/requestPasswordReset', body)
  }
})
