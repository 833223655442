export default (axios) => ({
  acceptInterview(id, payload) {
    return axios.post(`/api/vms/interview/${id}/accept`, payload)
  },

  declineInterview(id, payload) {
    return axios.post(`/api/vms/interview/${id}/decline`, payload)
  },

  acceptOffer(id) {
    return axios.post(`/api/vms/offer/${id}/accept`)
  },

  declineOffer(id) {
    return axios.post(`/api/vms/offer/${id}/decline`)
  }
})
