export default (axios) => ({
  get(id) {
    return axios.get(`/api/pool/${id}`)
  },

  addFreelancerBulk(body) {
    return axios.post('/api/pool/freelancer/add', body)
  },

  removeFreelancerBulk(body) {
    return axios.post('/api/pool/freelancer/remove', body)
  },

  acceptMembership(membershipId) {
    return axios.put(`/api/pool/membership/${membershipId}/accept`)
  },

  denyMembership(membershipId, body) {
    return axios.put(`/api/pool/membership/${membershipId}/deny`, body)
  },

  acceptMembershipBulk(body) {
    return axios.put('/api/pool/membership/acceptBulk', body)
  },

  denyMembershipBulk(body) {
    return axios.put('/api/pool/membership/denyBulk', body)
  },

  getInvitations(poolId, query) {
    return axios.get(`/api/pool/${poolId}/projectInvitations`, {
      params: {
        ...query
      }
    })
  },

  getOpenInvitations(poolId, query) {
    return axios.get(`/api/pool/${poolId}/projectInvitations/open`, {
      params: {
        ...query
      }
    })
  },

  contact(body) {
    return axios.post('/api/pool/contact', body)
  },

  getCategories(query) {
    return axios.get('/api/pool/categories', { params: { ...query } })
  }
})
