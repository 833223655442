export default (axios) => ({
  getBulk(freelancerId, query = {}) {
    return axios.get(`/api/freelancer/${freelancerId}/actionLog`, {
      params: {
        ...query
      }
    })
  },

  create(freelancerId, body) {
    return axios.post(`/api/freelancer/${freelancerId}/actionLog`, body)
  },

  update(freelancerId, logId, body) {
    return axios.put(`/api/freelancer/${freelancerId}/actionLog/${logId}`, body)
  },

  delete(freelancerId, logId) {
    return axios.delete(`/api/freelancer/${freelancerId}/actionLog/${logId}`)
  }
})
