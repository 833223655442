import { render, staticRenderFns } from "./NotePreview.vue?vue&type=template&id=618b64c9&scoped=true&"
import script from "./NotePreview.vue?vue&type=script&lang=js&"
export * from "./NotePreview.vue?vue&type=script&lang=js&"
import style0 from "./NotePreview.vue?vue&type=style&index=0&id=618b64c9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "618b64c9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseAvatarTemplate: require('/opt/atlassian/pipelines/agent/build/frontend/apps/msp/components/Base/AvatarTemplate.vue').default})
