export default function ({ app, store }, inject) {
  inject('pushEvent', async (event, payload, ignoreUser = false) => {
    const { name: clientName } = store.state.clientContext
    const eventData = {
      event,
      clientName,
      businessUnit: clientName,
      ...payload
    }

    if (!ignoreUser) {
      eventData.hrId = app.$auth.user?.id
    }

    await app.$gtm.push(eventData)
  })
}
