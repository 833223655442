export default (axios) => ({
  get(appId) {
    return axios.get(`/api/projectApplication/${appId}`)
  },

  delete(appId) {
    return axios.put(`/api/projectApplication/${appId}`)
  },

  rejectBulk(body) {
    return axios.post(`/api/projectApplication/reject`, body)
  },

  postToVMSBulk(body) {
    return axios.post('/api/projectApplication/upload', body)
  },

  reactivateUser(appId) {
    return axios.put(`/api/projectApplication/${appId}/reactivate`)
  },

  addToLabel(body) {
    return axios.post('/api/projectApplication/addLabel', body)
  },

  removeFromLabel(body) {
    return axios.post('/api/projectApplication/removeLabel', body)
  },

  getHistory(appId, query) {
    return axios.get(`/api/projectApplication/${appId}/history`, {
      params: {
        ...query
      }
    })
  },

  updateRates(appId, body) {
    return axios.post(`/api/projectApplication/${appId}/updateRates`, body)
  },

  updateHire(appId) {
    return axios.put(`api/projectApplication/${appId}/hire`)
  },

  updateUnhire(appId) {
    return axios.put(`api/projectApplication/${appId}/unhire`)
  },

  createManualLog(applicationId, body) {
    return axios.post(`/api/projectApplication/${applicationId}/history/manualLog`, body)
  },

  updateManualLog(applicationId, manualLogId, body) {
    return axios.put(
      `/api/projectApplication/${applicationId}/history/manualLog/${manualLogId}`,
      body
    )
  },

  deleteManualLog(applicationId, manualLogId) {
    return axios.delete(`/api/projectApplication/${applicationId}/history/manualLog/${manualLogId}`)
  },

  inviteExternalTalent(body) {
    return axios.post('/api/freelancer/projectApplication', body)
  },

  getAssessmentTests(applicationId) {
    return axios.get(`/api/projectApplication/${applicationId}/assessment/listAll`)
  }
})
