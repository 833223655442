export default (axios) => ({
  create(body) {
    return axios.post('/api/preApplicationQuestion', body)
  },

  createForJob(body) {
    return axios.post('/api/preApplicationQuestion/add', body)
  },

  updateBulk(body) {
    return axios.put('/api/preApplicationQuestion', body)
  },

  toggleVisibility(id, body) {
    return axios.put(`/api/preApplicationQuestion/${id}/toggleHide`, body)
  },

  delete(questionId) {
    return axios.delete(`/api/preApplicationQuestion/${questionId}`)
  },

  getByJobHandle(jobHandleId) {
    return axios.get(`/api/preApplicationQuestion/default/jobHandle/${jobHandleId}`)
  },

  getByLe(leId) {
    return axios.get(`/api/preApplicationQuestion/default/legalEntity/${leId}`)
  },

  getJobSpecific() {
    return axios.get('/api/preApplicationQuestion/jobSpecific/client')
  }
})
