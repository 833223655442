export const EManageProjectMode = Object.freeze({
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  UNPUBLISHED: 'UNPUBLISHED'
})

export const EProjectType = Object.freeze({
  PARTTIME: 'PARTTIME',
  FULLTIME: 'FULLTIME'
})

export const EEvergreenJobConfig = Object.freeze({
  HIDE: 'hide',
  REQUIRED: 'required',
  OPTIONAL: 'optional'
})

export const EProjectDetailsValue = Object.freeze({
  MAX_SUBMISSIONS: 'Max submissions',
  POSITIONS_TO_FILL: 'Positions to fill',
  DURATION: 'Duration',
  DEADLINE: 'Deadline'
})

export const ESuggestionTier = Object.freeze({
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: 'bronze'
})
