
import { mapGetters } from 'vuex'
import { EPollingStatus } from '@/helpers/enums'

export default {
  name: 'BasePollingTemplate',
  data() {
    return {
      pollingInterval: null
    }
  },

  computed: {
    ...mapGetters({
      pollingResults: 'polling/pollingResults'
    }),
    EPollingStatus() {
      return EPollingStatus
    }
  },

  watch: {
    pollingResults: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.length === 0) {
          this.$store.dispatch('polling/clearInterval')
          return clearTimeout(this.pollingInterval)
        }
        if (val.length > 0) {
          val.forEach((i) => {
            if (i.status === EPollingStatus.SUCCESSFUL) {
              this.pollingInterval = setTimeout(() => {
                this.taskCompleted(i.uuid)
              }, 5000)
            }
          })
        }

        const onlyFailedStatuses = val.every((result) => result.status === 'FAILED')

        if (val.length === 0 || onlyFailedStatuses) {
          return clearTimeout(this.pollingInterval)
        }

        if (val.length > 0) {
          const onlyFinalStatuses = val.every(
            (result) =>
              result.status === EPollingStatus.FAILED || result.status === EPollingStatus.SUCCESSFUL
          )

          if (document.hidden && !onlyFinalStatuses) {
            this.$store.dispatch('polling/clearInterval')
            this.$store.dispatch('polling/startPollingIdle')
          }
          if (!document.hidden && !onlyFinalStatuses) {
            this.$store.dispatch('polling/clearInterval')
            this.$store.dispatch('polling/startPolling')
          }
        }
      }
    }
  },

  mounted() {
    if (this.$useConfig(this.$EClientConfig.TASKS_SETTINGS) && this.$auth.loggedIn) {
      this.$store.dispatch('polling/startPolling')
    }
  },

  beforeDestroy() {
    this.$store.dispatch('polling/clearInterval')
  },

  methods: {
    async taskCompleted(id) {
      if (this.pollingResults.length === 0) {
        return
      }
      try {
        await this.$api.polling.acknowledgedTask(id)
        this.pollingResults.forEach((i) => {
          if (id === i.uuid) {
            this.$store.commit('polling/CLOSE_SNACKBAR', i.uuid)
          }
        })

        this.$nuxt.refresh()
      } catch (error) {}
    },
    goToJob(jobhandleId) {
      this.$router.push(`/project/${jobhandleId}/active`)
    }
  }
}
