import humanizeDuration from 'humanize-duration'
import { defineShortLocale } from '@twago/configs'
import { currencySign } from '@/helpers/index'
import { EDateType, EPeriods } from '@/helpers/enums'
import { clientTheme } from '@/config/theme'
import { EClientConfig, ELeConfig, mapClientConfig, mapLeConfig } from '@/config/config'

export default ({ $config, app, store, $vuetify }, inject) => {
  const applyClientTheme = clientTheme(store.state)

  $vuetify.theme.themes.light = {
    ...$vuetify.theme.defaults.light,
    ...applyClientTheme.light
  }

  inject('EClientConfig', EClientConfig)
  inject('useConfig', (key) => mapClientConfig(store.state.clientConfig, key))

  inject('ELeConfig', ELeConfig)
  inject('useLeConfig', (legalEntityName, key) =>
    mapLeConfig(store.state.leConfigs.get(legalEntityName), key)
  )

  inject('compactNumber', (number) => {
    return Math.abs(number) > 999
      ? Math.sign(number) * Number((Math.abs(number) / 1000).toString().slice(0, 3)) + 'K'
      : Math.sign(number) * Math.abs(number)
  })

  inject('dotNotationThousand', (number) => {
    return Math.abs(number) < 999
      ? Math.abs(number)
      : Math.abs(number) > 999 && Math.abs(number) < 10000
      ? Number(
          Math.abs(number).toString().slice(0, 1) + '.' + Math.abs(number).toString().slice(1)
        ).toFixed(3)
      : Math.abs(number) > 9999
      ? Number(
          Math.abs(number).toString().slice(0, 2) + '.' + Math.abs(number).toString().slice(2)
        ).toFixed(3)
      : Math.sign(number) * Math.abs(number)
  })

  inject('defineLocale', (code) => {
    const useShort = defineShortLocale(code)
    const useShortCode = ['ua-UA'].includes(code) ? 'en' : useShort
    return useShortCode
  })

  inject('truncate', (str, num) => (str.length < num ? str : str.slice(0, num).concat('...')))

  inject('showDate', (value, format = 'll') => {
    const currentlocale = app.$i18n.locale
    return app.$moment(value).locale(currentlocale).format(format)
  })

  // future proven approach after moment is dropped, please start using it now:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
  inject('displayDate', ({ value, format, options }) => {
    const currentlocale = app.$i18n.locale
    let defineFormat = {}

    if (value) {
      switch (format) {
        case EDateType.SHORT:
          defineFormat = {
            year: 'numeric',
            month: 'short'
          }
          break
        case EDateType.LONG:
          defineFormat = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }
          break
        case EDateType.YEAR:
          defineFormat = {
            year: 'numeric'
          }
          break
        case EDateType.LONG_WITH_TIME:
          defineFormat = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          }
          break
        default:
          defineFormat = {
            ...options
          }
          break
      }

      return new Intl.DateTimeFormat(currentlocale, defineFormat).format(new Date(value))
    }
  })

  inject('sendDate', (value) => {
    return app.$moment(value).format()
  })

  inject('getDateWithTZ', (value) => {
    const getTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
    const withTZ = app.$moment.tz(value, getTZ)

    return app.$moment(withTZ)
  })

  inject('humanizedDate', (start, end) => {
    const starting = app.$moment(start).utc()
    const ending = app.$moment(end).utc()
    const getMonths = ending.diff(starting, 'month')
    const humanizing = ending.diff(starting)
    let humanized = ''

    if (getMonths < 12) {
      humanized = humanizeDuration(humanizing, {
        language: app.$defineLocale(app.$i18n.locale),
        round: true,
        largest: 1
      })
    } else {
      humanized = humanizeDuration(humanizing, {
        language: app.$defineLocale(app.$i18n.locale),
        round: true,
        largest: 2
      })
    }

    return humanized
  })

  inject('dateRange', (dateFrom, dateTo, format = 'll') => {
    const printPeriod = (date) =>
      date ? app.$showDate(date, format) : app.$i18n.t('msp.v2.date.present')
    const from = printPeriod(dateFrom)
    const to = printPeriod(dateTo)

    return `${from} - ${to}`
  })

  inject('currencySign', (currency, unitType) => {
    let verb = ''

    switch (unitType) {
      case EPeriods.HR:
        verb = app.$i18n.t('msp.v2.rate.unit.hr')
        break
      case EPeriods.DAY:
        verb = app.$i18n.t('msp.v2.rate.unit.day')
        break
      case EPeriods.WEEK:
        verb = app.$i18n.t('msp.v2.rate.unit.week')
        break
      case EPeriods.MONTH:
        verb = app.$i18n.t('msp.v2.rate.unit.month')
        break
      case EPeriods.YEAR:
        verb = app.$i18n.t('msp.v2.rate.unit.year')
        break
      case EPeriods.PROJECT:
        verb = app.$i18n.t('msp.v2.rate.unit.project')
        break
      default:
        verb = app.$i18n.t('msp.v2.rate.unit.hr')
        break
    }

    return currencySign(currency, verb)
  })

  inject('parseCountryName', (code) => {
    if (code) {
      const getList = store.state.countriesList.find((i) => i.value === code)
      return getList.text
    }
  })

  inject('urlReady', (str) => {
    const title = str
      .trim()
      .replace(/\s+/g, '-')
      .replace(/\//g, '-')
      .replace(/-+/g, '-')
      .toLowerCase()

    return encodeURIComponent(title)
  })

  inject('scrollToInvalid', (container, errorsScope) => {
    const errorEntries = Object.entries(errorsScope)
    const invalidList = errorEntries
      .map(([key, value]) => ({ key, value }))
      .filter((err) => err.value.length)

    const getElement = container.refs[invalidList[0].key]
    const getItemId = getElement.$el.getElementsByTagName('input')[0]
    const scrollToId = getItemId?.id ? document.getElementById(getItemId.id) : getElement.$el

    scrollToId.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  })

  inject('copiedMsgSuccess', () => {
    app.$toast.fire(app.$i18n.t('msp.v2.message.generic.copied'))
  })

  inject('copiedMsgError', () => {
    app.$toast.fire(app.$i18n.t('msp.v2.message.generic.error'), { type: 'error' })
  })

  inject('getNextId', (arr, item) => {
    const idx = arr.findIndex((e) => Number(e) === Number(item))

    if (arr.length > 1 && idx === arr.length - 1) {
      return String(arr[idx - 1])
    } else if (idx >= 0 && idx < arr.length - 1) {
      return String(arr[idx + 1])
    } else {
      return null
    }
  })

  inject('generateEmailTemplate', (templateKey) => {
    const saveTemplates = []
    const buildChoices = []

    for (const item of store.state.messages.keys()) {
      if (item.startsWith(templateKey) && store.state.messages.get(item)) {
        saveTemplates.push(item)
      }
    }

    saveTemplates.sort()

    for (let i = 0; i < saveTemplates.length; i++) {
      if (i % 3 === 0) {
        const template = saveTemplates[i]
        const splitStr = template.split('.').slice(0, -1)
        const index = Number(splitStr.pop())

        const showName = app.$i18n.t(`${templateKey}.${index}.shown_name`)
        const text = app.$i18n.t(`${templateKey}.${index}.text`)
        const type = app.$i18n.t(`${templateKey}.${index}.type`)

        if (showName) {
          buildChoices.push({ showName, text, type })
        }
      }
    }

    return buildChoices
  })

  inject('ampersandCorrection', (value) => {
    if (value) {
      return value.replaceAll('&amp;', '&')
    }
  })

  inject('clientCoreAsset', (uuid, params = {}) => {
    const baseURL = $config.clientCoreApiUrl
    const query = new URLSearchParams({ format: 'webp', ...params })

    return `${baseURL}/assets/${uuid}${query.toString() ? `?${query}` : ''}`
  })

  inject('clientMainLogo', (params = {}) =>
    app.$clientCoreAsset(store.state.clientContext.mspLogo, params)
  )

  inject('clientSecondaryLogo', (params = {}) =>
    app.$clientCoreAsset(store.state.clientContext.projectLogo, {
      width: 48,
      height: 48,
      ...params
    })
  )

  inject('flagUrl', (countryCode) => `https://flagcdn.com/${countryCode.toLowerCase()}.svg`)

  inject('yearsList', () => {
    const currentYear = new Date().getFullYear()
    const min = currentYear - 90
    const max = currentYear + 10
    const years = []

    for (let i = currentYear; i <= max; i++) {
      years.unshift(i)
    }

    for (let i = currentYear; i >= min; i--) {
      years.push(i)
    }

    return years
  })
}
